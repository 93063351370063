<template>
  <div class="data-table-container">
    <!-- Add loading overlay -->
    <div v-if="dataStore.isLoading" class="loading-overlay">
      <div class="spinner"></div>
    </div>
    
    <table v-if="rows.length" class="data-table table">
      <thead>
        <tr>
          <th v-for="header in headers" :key="header" @click="sortBy(header)" class="resizable">
            <div class="header-content">
              <div class="header-text">
                <template v-if="isSpecialHeader(getDisplayName(header))">
                  <div class="header-main">{{ getMainHeaderText(getDisplayName(header)) }}</div>
                  <div class="header-sub">{{ getSubHeaderText(getDisplayName(header)) }}</div>
                </template>
                <template v-else>
                  {{ getDisplayName(header) }}
                </template>
              </div>
              <span v-if="sortColumn === header" class="sort-arrow">{{ sortOrder === 'asc' ? '▲' : '▼' }}</span>
            </div>
            <div class="resize-handle"
                 @mousedown.stop="startResize($event, header)"
                 @click.stop></div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in rows" :key="index"
            :class="{ 'selected-row': selectedRow === row, 'hovered-row': index === hoveredRowIndex }"
            @click="selectRow(row)"
            @mouseover="hoveredRowIndex = index"
            @mouseleave="hoveredRowIndex = null">
          <td v-for="header in headers" :key="header" :class="getColumnClass(header)">
            <template v-if="header === 'phase' && row[header]">
              <PhaseProgressBar :phase="row[header]" />
            </template>
            <template v-else-if="header === 'source_type' && row[header]">
              <div class="source-type">
                <i :class="getSourceTypeIcon(row[header])"></i>
                <a :href="row.source" target="_blank">{{ row[header] }}</a>
              </div>
            </template>
            <template v-else-if="isValueWithUnit(row[header])">
              <div class="value-unit" v-if="row[header].value !== null">
                <span class="value">{{ formatNumericValue(row[header].value) }}</span>
                <span class="unit">{{ row[header].unit }}</span>
              </div>
              <span v-else>-</span>
            </template>
            <template v-else-if="header === 'trial' && row[header]">
              <div class="trial-links">
                <template v-for="(trialId, idx) in row[header]" :key="idx">

                  <a v-if="trialId.includes('NCT')" :href="`https://clinicaltrials.gov/study/${trialId}`" 
                     target="_blank" 
                     @click.stop>{{ trialId }}</a>
                  <span v-else>{{ trialId }}</span>
                  <span v-if="idx < row[header].length - 1">, </span>
                </template>
              </div>
            </template>
            <template v-else>
              <span :class="{ 'numeric': isNumeric(row[header]) }">
                {{ formatValue(row[header]) || '-' }}
              </span>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <p v-else>No data available</p>
  </div>
</template>

<script>
import { useDataStore } from '../stores/dataStore'
import { computed, ref, onMounted, onUnmounted, watch, nextTick } from 'vue'
import PhaseProgressBar from './PhaseProgressBar.vue'

export default {
  name: 'DataTable',
  components: {
    PhaseProgressBar
  },
  setup() {
    const dataStore = useDataStore()
    const sortColumn = ref(dataStore.config.defaultSort.column)
    const sortOrder = ref(dataStore.config.defaultSort.order)
    const hoveredRowIndex = ref(null)

    // Update rows computed property to use filteredData instead of data
    const rows = computed(() => dataStore.filteredData)
    const headers = computed(() => {
      const mandatoryColumns = new Set([
        ...dataStore.visibleColumns,
        ...getActiveFilterColumns(),
        ...getPlotVariableColumns()
      ]);
      return Array.from(mandatoryColumns);
    })

    const sortedRows = computed(() => {
      if (sortColumn.value) {
        return [...rows.value].sort((a, b) => {
          const aVal = a[sortColumn.value]
          const bVal = b[sortColumn.value]
          
          // Handle null/undefined values
          const isANull = !aVal || (isValueWithUnit(aVal) && aVal.value === null)
          const isBNull = !bVal || (isValueWithUnit(bVal) && bVal.value === null)
          if (isANull && isBNull) return 0
          if (isANull) return 1
          if (isBNull) return -1
          
          // Special handling for date columns
          if (['publication_date', 'completion_date'].includes(sortColumn.value)) {
            const aDate = new Date(aVal)
            const bDate = new Date(bVal)
            if (aDate < bDate) return sortOrder.value === 'asc' ? -1 : 1
            if (aDate > bDate) return sortOrder.value === 'asc' ? 1 : -1
            return 0
          }
          
          // Handle objects with value and unit properties
          if (isValueWithUnit(aVal) && isValueWithUnit(bVal)) {
            // Compare units first
            const aUnit = aVal.unit || ''
            const bUnit = bVal.unit || ''
            if (aUnit !== bUnit) {
              return sortOrder.value === 'asc' 
                ? aUnit.localeCompare(bUnit)
                : bUnit.localeCompare(aUnit)
            }
            
            // If units are the same, compare values
            const aNum = parseFloat(aVal.value || 0)
            const bNum = parseFloat(bVal.value || 0)
            if (aNum < bNum) return sortOrder.value === 'asc' ? -1 : 1
            if (aNum > bNum) return sortOrder.value === 'asc' ? 1 : -1
            return 0
          }
          
          // Original sorting logic for other columns
          const aNum = parseFloat(aVal)
          const bNum = parseFloat(bVal)
          
          if (!isNaN(aNum) && !isNaN(bNum)) {
            if (aNum < bNum) return sortOrder.value === 'asc' ? -1 : 1
            if (aNum > bNum) return sortOrder.value === 'asc' ? 1 : -1
            return 0
          }
          
          // Fall back to string comparison
          if (aVal < bVal) return sortOrder.value === 'asc' ? -1 : 1
          if (aVal > bVal) return sortOrder.value === 'asc' ? 1 : -1
          return 0
        })
      }
      return rows.value
    })

    const sortBy = (column) => {
      if (sortColumn.value === column) {
        sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc'
      } else {
        sortColumn.value = column
        sortOrder.value = 'asc'
      }
    }

    const selectRow = (row) => {
      if (dataStore.selectedRow === row) {
        dataStore.setSelectedRow(null)
      } else {
        dataStore.setSelectedRow(row)
      }
    }

    const getColumnClass = (header) => {
      if (header === 'phase') return 'column-phase'
      if (header === 'trial') return 'column-trial'
      if (header === 'drug_name') return 'column-drug-name'
      if (['mechanisms', 'other_names', "sponsor"].includes(header)) return 'column-align-left'
      if (header === 'publication_date' || header === 'completion_date') return 'column-date'
      return ''
    }

    const getSourceTypeIcon = (sourceType) => {
      const icons = {
        'Publication': 'fa-book',
        'Clinicaltrials.gov': 'fa-hospital',
        'Company materials': 'fa-building',
        'Conference': 'fa-users',
        'default': 'fa-question-circle'
      }
      return `fas ${icons[sourceType] || icons.default}`
    }

    const getDisplayName = (header) => {
      return dataStore.getDisplayName(header);
    }

    const formatValue = (value) => {
      if (value === null || value === undefined) return '-'
      if (Array.isArray(value)) {
        return value.join(', ')
      }
      if (value !== null && typeof value === 'object' && !Array.isArray(value) && !isValueWithUnit(value)) {
        return '-'
      }
      return value
    }

    // Add new method to handle keyboard navigation
    const handleKeyDown = (event) => {
      if (!sortedRows.value.length) return
      
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault()
        
        const currentIndex = dataStore.selectedRow 
          ? sortedRows.value.indexOf(dataStore.selectedRow)
          : -1
        
        let newIndex
        if (event.key === 'ArrowUp') {
          newIndex = currentIndex <= 0 ? sortedRows.value.length - 1 : currentIndex - 1
        } else {
          newIndex = currentIndex >= sortedRows.value.length - 1 ? 0 : currentIndex + 1
        }
        
        selectRow(sortedRows.value[newIndex])
      }
    }

    // Add onMounted to set up event listener
    onMounted(() => {
      window.addEventListener('keydown', handleKeyDown)
    })

    // Add onUnmounted to clean up
    onUnmounted(() => {
      window.removeEventListener('keydown', handleKeyDown)
    })

    // Add new method to handle scrolling
    const scrollToSelectedRow = () => {
      if (!dataStore.selectedRow) return
      
      // Find the DOM element of the selected row
      const selectedElement = document.querySelector('.selected-row')
      if (!selectedElement) return

      // Get the container element
      const container = document.querySelector('.data-table-container')
      if (!container) return

      // Calculate positions
      const containerRect = container.getBoundingClientRect()
      const selectedRect = selectedElement.getBoundingClientRect()
      
      // Calculate scroll position to center the row
      const scrollTop = container.scrollTop + 
        selectedRect.top - 
        containerRect.top - 
        (containerRect.height / 2) + 
        (selectedRect.height / 2)

      // Smooth scroll to position
      container.scrollTo({
        top: scrollTop,
        behavior: 'smooth'
      })
    }


    // Add helper functions
    const getActiveFilterColumns = () => {
      const activeColumns = [];
      
      // Add columns with active multichoice filters
      Object.entries(dataStore.filterValues).forEach(([column, values]) => {
        if (values && values.length > 0) {
          activeColumns.push(column);
        }
      });
      
      // Add columns with active range filters
      Object.entries(dataStore.selectedRanges).forEach(([column, range]) => {
        if (range && (range.min !== null || range.max !== null)) {
          activeColumns.push(column);
        }
      });
      
      return activeColumns;
    };

    const getPlotVariableColumns = () => {
      const plotColumns = [];
      
      // Add X variable if selected
      if (dataStore.selectedXVar) {
        plotColumns.push(dataStore.selectedXVar);
      }
      
      // Add Y variable if selected
      if (dataStore.selectedYVar) {
        plotColumns.push(dataStore.selectedYVar);
      }
      
      return plotColumns;
    };

    const isValueWithUnit = (value) => {
      return value && typeof value === 'object' && (
        // Check for both value/unit structure and null values
        ('value' in value && 'unit' in value) ||
        // Also return true for endpoint objects that might have null values
        Object.prototype.hasOwnProperty.call(value, 'value')
      );
    }

    const isNumeric = (value) => {
      return !isNaN(parseFloat(value)) && isFinite(value);
    }

    const formatNumericValue = (value) => {
      if (value === null || value === undefined) return '-';
      if (Array.isArray(value)) {
        return value.join(', ')
      }
      if (!isNumeric(value)) return value;
      // Format to 2 decimal places for endpoint values
      return Number.isInteger(value) ? value.toString() : Number(value).toFixed(2);
    }

    const isSpecialHeader = (header) => {
      return header.includes(' - ') || header.includes('(');
    }

    const getMainHeaderText = (header) => {
      return header.split('(')[0].trim();
    }

    const getSubHeaderText = (header) => {
      const match = header.match(/\((.*?)\)/);
      return match ? match[1] : '';
    }

    watch(() => dataStore.selectedRow, (newSelectedRow) => {
      if (newSelectedRow) {
        // Use nextTick to ensure DOM is updated before scrolling
        nextTick(() => {
          scrollToSelectedRow();
        });
      }
    }, { immediate: true });

    // Add new refs for resizing
    const isResizing = ref(false)
    const currentResizeHeader = ref(null)
    const startX = ref(0)
    const startWidth = ref(0)

    const startResize = (event, header) => {
      isResizing.value = true
      currentResizeHeader.value = header
      startX.value = event.pageX
      
      // Get the current width of the column
      const headerCell = event.target.parentElement
      startWidth.value = headerCell.offsetWidth
      
      // Add event listeners
      document.addEventListener('mousemove', handleResize)
      document.addEventListener('mouseup', stopResize)
      
      // Add class to prevent text selection while resizing
      document.body.classList.add('resizing')
    }

    const handleResize = (event) => {
      if (!isResizing.value) return
      
      const diff = event.pageX - startX.value
      const newWidth = Math.max(100, startWidth.value + diff) // Minimum width of 100px
      
      // Find all cells in this column and update their width
      const cells = document.querySelectorAll(
        `th:nth-child(${Array.from(headers.value).indexOf(currentResizeHeader.value) + 1}), 
         td:nth-child(${Array.from(headers.value).indexOf(currentResizeHeader.value) + 1})`
      )
      
      cells.forEach(cell => {
        cell.style.width = `${newWidth}px`
        cell.style.minWidth = `${newWidth}px`
      })
    }

    const stopResize = () => {
      isResizing.value = false
      currentResizeHeader.value = null
      document.removeEventListener('mousemove', handleResize)
      document.removeEventListener('mouseup', stopResize)
      document.body.classList.remove('resizing')
    }

    return {
      rows: sortedRows,
      headers,
      getDisplayName,
      sortColumn,
      sortOrder,
      sortBy,
      hoveredRowIndex,
      selectRow,
      getColumnClass,
      getSourceTypeIcon,
      dataStore,
      selectedRow: computed(() => dataStore.selectedRow), // Replace direct selectedRow reference
      formatValue,
      scrollToSelectedRow,
      isValueWithUnit,
      isNumeric,
      formatNumericValue,
      isSpecialHeader,
      getMainHeaderText,
      getSubHeaderText,
      startResize,
    }
  }
}
</script>

<style scoped>
.data-table-container {
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: auto;
}

/* Default scrollbar state - 95% transparent with transition */
.data-table-container::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

.data-table-container::-webkit-scrollbar-track {
  background: transparent;
}

.data-table-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 5px solid transparent;
  background-clip: padding-box;
  transition: background-color 0.3s ease;
}

/* Hover state - more visible */
.data-table-container:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.data-table-container:hover::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
}

/* For Firefox */
.data-table-container {
  scrollbar-color: rgba(0, 0, 0, 0.05) transparent;
  transition: scrollbar-color 0.3s ease;
}

.data-table-container:hover {
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  transition: scrollbar-color 0.3s ease;
}

.data-table {
  width: 100%;
  height: 100%;
}

.table {
  font-size: 0.9rem;
  width: 100%;
  table-layout: auto;
  height: auto; /* Remove height: 100% to prevent stretching */
}

.table th, .table td {
  padding: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 100px;
  max-width: 250px; /* Add maximum width constraint */
  max-height: 2rem;
  height: 2rem;
}

.selected-row {
  background-color: #fffacd !important;
  font-weight: bold;
}

.table th {
  cursor: pointer;
  user-select: none;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  text-align: center;
}

.table th:hover {
  background-color: #f0f0f0;
}

/* ... Add the rest of the provided styles ... */

.source-type {
  display: flex;
  align-items: center;
  gap: 8px;  /* Add padding between icon and text */
}

.source-type i {
  color: #3498db;  /* Light blue color for the icon */
}

/* Remove scoped attribute from link styles since we want them global */
:deep(.source-type a) {
  color: #3498db;
  text-decoration: none;
}

:deep(.source-type a:hover) {
  text-decoration: underline;
}

.table tbody tr {
  cursor: pointer;
}

.value-unit {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  gap: 4px;
  min-width: 100px; /* Ensure minimum width for value-unit pairs */
}

.value {
  text-align: right;
  min-width: 60px; /* Ensure minimum width for values */
}

.unit {
  color: #666;
  font-size: 0.85em;
  min-width: 30px; /* Ensure minimum width for units */
  text-align: left;
}

.numeric {
  display: block;
  text-align: right;
}

.table td {
  /* ... existing styles ... */
  text-align: right;
}

.table td:has(.value-unit), 
.table td:has(.numeric) {
  text-align: right;
}

.table td.column-drug-name {
  text-align: left;
  max-width: 200px; /* Ensure max-width is applied to drug name column */
}

.table td:has(.source-type) {
  text-align: left;
}

.table td.column-phase {
  text-align: center;
}

.trial-links {
  text-align: left;
}

.trial-links a {
  color: #3498db;
  text-decoration: none;
}

.trial-links a:hover {
  text-decoration: underline;
}

.table td.column-date {
  text-align: left !important; /* Add !important to override numeric text alignment */
}

.table td.column-date .numeric {
  text-align: left !important; /* Override numeric class alignment for dates */
  display: inline; /* Change display to inline to prevent block behavior */
}

.header-main {
  font-size: 0.9em;
  line-height: 1.2;
  text-align: center;
}

.header-sub {
  font-size: 0.75em;
  color: #666;
  line-height: 1.1;
  text-align: center;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-text {
  flex-grow: 1;
}

.sort-arrow {
  margin-left: 8px;
}

.table td.column-drug-name,
.table td:has(.source-type),
.table td:has(.trial-links),
.table td.column-date,
.table td:has(.mechanisms),  /* Add this selector */
.table td.column-align-left {  /* Add this selector as fallback */
  text-align: left;
}

.resizable {
  position: relative;
}

.resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  cursor: col-resize;
  background-color: transparent;
}

.resize-handle:hover {
  background-color: #ddd;
}

/* Add to prevent text selection while resizing */
:global(body.resizing) {
  cursor: col-resize;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

:global(body.resizing .table th) {
  cursor: col-resize;
}
</style>
