<template>
  <div class="tag-select" ref="selectContainer">
    <div class="selected-tags" :class="{ 'has-overflow': hasOverflow }">
      <span v-for="tag in displayedTags" :key="tag" class="tag">
        {{ tag === null || tag === '' ? 'N/A' : tag }}
        <button @click="removeTag(tag)" class="tag-remove">×</button>
      </span>
      <button 
        v-if="modelValue.length > 3 && !showAllTags" 
        @click="showAllTags = true" 
        class="show-more-btn"
      >
        Show more... ({{ modelValue.length - 3 }} more)
      </button>
    </div>

    <div class="tag-input-container">
      <input
        type="text"
        v-model="searchText"
        :placeholder="placeholder"
        @click="showModal = true"
        @keydown.enter.prevent="addTag"
        @keydown.backspace="handleBackspace"
        @focus="handleFocus"
        @blur="handleBlur"
        class="tag-input"
        ref="inputRef"
      />
    </div>
    
    <SelectionModal
      v-model:show="showModal"
      :title="'Select Options'"
      :items="modalItems"
      :selected-values="modelValue"
      @update:selected-values="newValues => emit('update:modelValue', newValues)"
      :searchPlaceholder="'Search options...'"
    />
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits, onMounted, watch, nextTick } from 'vue';
import SelectionModal from './SelectionModal.vue';

const props = defineProps({
  modelValue: {
    type: Array,
    required: true,
    default: () => []
  },
  options: {
    type: Array,
    required: true
  },
  placeholder: {
    type: String,
    default: 'Select or type to search...'
  }
});

const emit = defineEmits(['update:modelValue']);
const searchText = ref('');
const inputRef = ref(null);
const selectContainer = ref(null);

const showAllTags = ref(false);

const displayedTags = computed(() => {
  if (!props.modelValue) return [];
  if (showAllTags.value || props.modelValue.length <= 3) {
    return props.modelValue;
  }
  return props.modelValue.slice(0, 3);
});

const showModal = ref(false);

const modalItems = computed(() => {
  return props.options.map(option => ({
    value: option,
    label: option === null || option === '' ? 'N/A' : String(option)
  }));
});

const removeTag = (tag) => {
  emit('update:modelValue', props.modelValue.filter(t => t !== tag));
};

const addTag = () => {
  if (!searchText.value) return;
  if (modalItems.value.length > 0) {
    emit('update:modelValue', [...props.modelValue, modalItems.value[0].value]);
    searchText.value = '';
  }
};

const handleBackspace = (event) => {
  if (searchText.value === '' && props.modelValue.length > 0) {
    event.preventDefault();
    removeTag(props.modelValue[props.modelValue.length - 1]);
  }
};

const updatePosition = () => {
  checkOverflow();
};

const hasOverflow = ref(false);

const checkOverflow = () => {
  if (selectContainer.value) {
    const tagsContainer = selectContainer.value.querySelector('.selected-tags');
    hasOverflow.value = tagsContainer.scrollHeight > tagsContainer.clientHeight;
  }
};

onMounted(() => {
  window.addEventListener('scroll', updatePosition, true);
  window.addEventListener('resize', updatePosition);
  
  const observer = new ResizeObserver(checkOverflow);
  if (selectContainer.value) {
    observer.observe(selectContainer.value);
  }
  
  checkOverflow();
});

watch(() => props.modelValue, () => {
  nextTick(() => {
    checkOverflow();
  });
}, { deep: true });
</script>

<style scoped>
.tag-select {
  position: relative;
  width: 100%;
}

.tag-input-container {
  margin-bottom: 0.5rem;
}

.tag-input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.selected-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  max-height: 150px;
  overflow-y: auto;
  padding: 0.25rem;
}

.selected-tags.has-overflow {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.tag {
  display: inline-flex;
  align-items: center;
  background-color: #e9ecef;
  padding: 0.15rem 0.35rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
}

.tag-remove {
  background: none;
  border: none;
  margin-left: 0.15rem;
  padding: 0 0.15rem;
  cursor: pointer;
  font-size: 0.9rem;
  line-height: 1;
  color: #666;
}

.tag-remove:hover {
  color: #000;
}

.show-more-btn {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 0.75rem;
  padding: 0.15rem 0.35rem;
  text-decoration: underline;
}

.show-more-btn:hover {
  color: #000;
}

/* Add smooth scrollbar for webkit browsers */
.selected-tags::-webkit-scrollbar {
  width: 6px;
}

.selected-tags::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.selected-tags::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.selected-tags::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style> 