<template>
  <svg viewBox="0 0 24 24" width="1em" height="1em" :class="iconClass">
    <g v-if="type === 'bar'">
      <rect x="4" y="14" width="4" height="10" />
      <rect x="10" y="8" width="4" height="16" />
      <rect x="16" y="2" width="4" height="22" />
    </g>
    <g v-else-if="type === 'boxplot'">
      <line x1="6" y1="2" x2="6" y2="22" stroke="currentColor" />
      <rect x="4" y="6" width="4" height="12" fill="none" stroke="currentColor" />
      <line x1="4" y1="12" x2="8" y2="12" stroke="currentColor" />
      <line x1="18" y1="4" x2="18" y2="24" stroke="currentColor" />
      <rect x="16" y="8" width="4" height="12" fill="none" stroke="currentColor" />
      <line x1="16" y1="14" x2="20" y2="14" stroke="currentColor" />
    </g>
    <g v-else-if="type === 'scatter'">
      <circle cx="5" cy="5" r="1.5" />
      <circle cx="10" cy="18" r="1.5" />
      <circle cx="15" cy="7" r="1.5" />
      <circle cx="19" cy="12" r="1.5" />
      <circle cx="7" cy="10" r="1.5" />
      <circle cx="12" cy="15" r="1.5" />
      <circle cx="17" cy="3" r="1.5" />
      <circle cx="3" cy="19" r="1.5" />
    </g>
    <g v-else-if="type === 'x-asc'">
      <text x="2" y="18" font-size="16">X</text>
    </g>
    <g v-else-if="type === 'y-asc'">
      <text x="2" y="18" font-size="16">Y</text>
      <polygon points="18,6 22,12 14,12" />
    </g>
    <g v-else-if="type === 'y-desc'">
      <text x="2" y="18" font-size="16">Y</text>
      <polygon points="18,18 22,12 14,12" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ChartIcons',
  props: {
    type: {
      type: String,
      required: true
    },
    iconClass: {
      type: String,
      default: ''
    }
  }
}
</script>

