<template>
  <SelectionModal
    :show="show"
    @update:show="$emit('update:show', $event)"
    :title="'Column Visibility'"
    :items="columnItems"
    v-model:selectedValues="selectedColumns"
    searchPlaceholder="Search columns..."
  />
</template>

<script setup>
import { computed } from 'vue';
import { useDataStore } from '../stores/dataStore';
import SelectionModal from './SelectionModal.vue';

const dataStore = useDataStore();

// Transform columns into the format expected by SelectionModal
const columnItems = computed(() => {
  const allColumns = new Set();
  dataStore.data.forEach(dataPoint => {
    Object.keys(dataPoint).forEach(key => {
      if (key.includes(' drug') || key.includes(' placebo') || key.includes(' diff') || 
          typeof dataPoint[key] !== 'object' && !key.startsWith('_')) {
        allColumns.add(key);
      }
    });
  });

  return Array.from(allColumns).map(column => ({
    value: column,
    label: dataStore.getDisplayName(column)
  }));
});

// Computed property for two-way binding with visible columns
const selectedColumns = computed({
  get: () => dataStore.visibleColumns,
  set: (newColumns) => {
    dataStore.visibleColumns = newColumns;
  }
});
</script> 