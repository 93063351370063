export function flattenObject(obj, prefix = '') {
  const flattened = {};

  for (const [key, value] of Object.entries(obj)) {
    if (value === null || value === undefined) {
      flattened[prefix + key] = '';
      continue;
    }

    if (typeof value === 'object' && !Array.isArray(value)) {
      if (key === 'endpoints') {
        // Handle endpoints specially
        Object.entries(value).forEach(([endpointName, endpointData]) => {
          // Flatten endpoint values
          if (endpointData.drug?.values) {
            endpointData.drug.values.forEach((val, idx) => {
              flattened[`${endpointName}_drug_t${idx}`] = val;
            });
          }
          if (endpointData.placebo?.values) {
            endpointData.placebo.values.forEach((val, idx) => {
              flattened[`${endpointName}_placebo_t${idx}`] = val;
            });
          }
          // Include metadata
          if (endpointData.meta) {
            Object.entries(endpointData.meta).forEach(([metaKey, metaValue]) => {
              if (Array.isArray(metaValue)) {
                metaValue.forEach((val, idx) => {
                  flattened[`${endpointName}_${metaKey}_${idx}`] = val;
                });
              } else {
                flattened[`${endpointName}_${metaKey}`] = metaValue;
              }
            });
          }
        });
      } else if (key === 'demographics') {
        // Handle demographics specially
        Object.entries(value).forEach(([demoKey, demoValue]) => {
          if (typeof demoValue === 'object') {
            Object.entries(demoValue).forEach(([subKey, subValue]) => {
              flattened[`demographics_${demoKey}_${subKey}`] = subValue;
            });
          } else {
            flattened[`demographics_${demoKey}`] = demoValue;
          }
        });
      } else {
        // Recursively flatten nested objects
        Object.assign(flattened, flattenObject(value, `${prefix}${key}_`));
      }
    } else if (Array.isArray(value)) {
      flattened[prefix + key] = value.join(';');
    } else {
      flattened[prefix + key] = value;
    }
  }

  return flattened;
}
