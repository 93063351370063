import { createRouter, createWebHistory } from 'vue-router'
import DiseaseView from './components/DiseaseView.vue'

const routes = [
  {
    path: '/',
    redirect: '/obesity'
  },
  {
    path: '/:disease',
    name: 'DiseaseView',
    component: DiseaseView,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router