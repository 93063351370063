<template>
    <div v-if="show" class="selection-modal-overlay" @click="closeModal">
      <div class="selection-modal" @click.stop>
        <div class="modal-header">
          <h3>{{ title }}</h3>
          <button class="close-btn" @click="closeModal">
            <i class="fas fa-times"></i>
          </button>
        </div>
        
        <div class="search-box">
          <input 
            type="text" 
            v-model="searchText" 
            :placeholder="searchPlaceholder"
            class="form-control"
          />
        </div>
  
        <div class="selection-list">
          <div v-for="item in filteredItems" 
               :key="item.value" 
               class="selection-item">
            <label class="form-check-label">
              <input
                type="checkbox"
                :value="item.value"
                :checked="isSelected(item.value)"
                @change="toggleSelection(item.value)"
                class="form-check-input"
              />
              {{ item.label }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, computed } from 'vue';
  
  const props = defineProps({
    show: Boolean,
    title: {
      type: String,
      default: 'Select Items'
    },
    items: {
      type: Array,
      required: true,
      // Array of { value: any, label: string }
    },
    selectedValues: {
      type: Array,
      required: true
    },
    searchPlaceholder: {
      type: String,
      default: 'Search...'
    }
  });
  
  const emit = defineEmits(['update:show', 'update:selectedValues']);
  
  const searchText = ref('');
  
  const closeModal = () => {
    emit('update:show', false);
  };
  
  const filteredItems = computed(() => {
    const search = searchText.value.toLowerCase();
    const filtered = props.items.filter(item => 
      item.label.toLowerCase().includes(search)
    );
  
    // Split into selected and unselected
    const selected = filtered.filter(item => isSelected(item.value));
    const unselected = filtered.filter(item => !isSelected(item.value));
  
    // Sort both arrays by label
    const sortByLabel = (a, b) => a.label.localeCompare(b.label);
    selected.sort(sortByLabel);
    unselected.sort(sortByLabel);
  
    return [...selected, ...unselected];
  });
  
  const isSelected = (value) => {
    return props.selectedValues.includes(value);
  };
  
  const toggleSelection = (value) => {
    const newSelection = isSelected(value)
      ? props.selectedValues.filter(v => v !== value)
      : [...props.selectedValues, value];
    emit('update:selectedValues', newSelection);
  };
  </script>
  
  <style scoped>
  .selection-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .selection-modal {
    background-color: white;
    border-radius: 8px;
    padding: 1.5rem;
    width: 90%;
    max-width: 500px;
    height: 80vh;
    display: flex;
    flex-direction: column;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  .modal-header h3 {
    margin: 0;
  }

  .close-btn {
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 0.5rem;
  }

  .search-box {
    margin-bottom: 1rem;
  }

  .search-box input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  .selection-list {
    overflow-y: auto;
    flex: 1;
    min-height: 300px;
  }

  .selection-item {
    padding: 0.5rem;
    cursor: pointer;
  }

  .selection-item:hover {
    background-color: #f5f5f5;
  }

  .form-check-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
  }

  .form-check-input {
    cursor: pointer;
  }
  </style>