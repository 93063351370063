<template>
  <div class="phase-progress-container">
    <div class="phase-progress-bar">
      <div class="progress-fill" :style="{ width: progressWidth }"></div>
    </div>
    <span class="phase-number">{{ phaseNumber }}</span>
  </div>
</template>

<script>
export default {
  name: 'PhaseProgressBar',
  props: {
    phase: {
      type: [String, Number],
      required: true,
      validator: (value) => {
        const num = typeof value === 'string' ? parseInt(value.replace('Phase ', '')) : value;
        return num >= 0 && num <= 4;
      }
    }
  },
  computed: {
    phaseNumber() {
      return typeof this.phase === 'string' ? 
        (this.phase === "Preclinical" ? "pre" : this.phase) : 
        `Phase ${this.phase}`;
    },
    progressWidth() {
      const num = typeof this.phase === 'string' ? 
        parseInt(this.phase.replace('Phase ', '')) : 
        this.phase;
      return `${(num / 4) * 100}%`;
    }
  }
}
</script>

<style scoped>
.phase-progress-container {
  display: flex;
  align-items: center;
  gap: 8px;

}

.phase-progress-bar {
  position: relative;
  width: 60px;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
}

.progress-fill {
  position: absolute;
  height: 100%;
  background-color: #3498db;
  transition: width 0.3s ease;
}

.phase-number {
  font-size: 0.8rem;
  color: #666;
}
</style> 