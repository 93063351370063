<template>
  <div class="details-component" v-if="selectedRow">
    <div>
      <button class="close-button" @click="closeDetails">×</button>
      <h2>{{ selectedRow.drug_name }}</h2>
      <p class="dose-info" v-if="selectedRow.dose">
        <strong>{{ Array.isArray(selectedRow.dose.value) ? selectedRow.dose.value.join(', ') : selectedRow.dose.value }} {{ selectedRow.dose.unit }}</strong>  
        {{ selectedRow.dose.route }}  {{ selectedRow.dose.form }}
      </p>
      <div class="trial-info">
        <div class="info-grid">
          <div class="info-item" v-if="selectedRow.trial?.length">
            <label>Trials</label>
            <span>{{ selectedRow.trial.join(', ') }}</span>
          </div>
          <div class="info-item" v-if="selectedRow.phase">
            <label>Phase</label>
            <span>{{ selectedRow.phase }}</span>
          </div>
          <div class="info-item" v-if="selectedRow.fda_specific || selectedRow.fda_any">
            <label>Status</label>
            <span>{{ selectedRow.fda_specific || selectedRow.fda_any }}</span>
          </div>
          <div class="info-item" v-if="selectedRow.completion_date">
            <label>Completion</label>
            <span>{{ formatDate(selectedRow.completion_date) }}</span>
          </div>
          <div class="info-item" v-if="selectedRow.num_patients">
            <label>Patients</label>
            <span>{{ selectedRow.num_patients }}</span>
          </div>
          <div class="info-item" v-if="selectedRow.num_sites">
            <label>Sites</label>
            <span>{{ selectedRow.num_sites }}</span>
          </div>
          <div class="info-item" v-if="selectedRow.num_countries">
            <label>Countries</label>
            <span>{{ selectedRow.num_countries }}</span>
          </div>
        </div>
        <div class="sponsor" v-if="selectedRow.sponsor?.length">
          <label>Sponsors:</label>
          <span>{{ Array.isArray(selectedRow.sponsor) ? selectedRow.sponsor.join(', ') : selectedRow.sponsor }}</span>
        </div>
        <div class="mechanisms" v-if="selectedRow.mechanisms?.length">
          <label>Mechanisms:</label>
          <span>{{ selectedRow.mechanisms?.join(', ') }}</span>
        </div>
        <a v-if="selectedRow.source" :href="selectedRow.source" target="_blank" class="source-link">
          View Source Document
        </a>
      </div>
      <a 
        v-if="validationToken" 
        :href="`${baseUrl}/validation/?token=${validationToken}&id=${selectedRow.result_id}&mode=numbers`"
        class="validation-link"
        target="_blank"
      >
        Validate this entry
      </a>
      <!-- pre>{{ JSON.stringify(selectedRow, null, 2) }}</pre-->

      <h3>Endpoints</h3>
      <table v-if="formattedEndpoints.length">
        <thead>
          <tr>
            <th></th>
            <th>Endpoint</th>
            <th class="number">Drug</th>
            <th></th>
            <th class="number">Placebo</th>
            <th></th>
            <th class="number">Difference</th>
            <th></th>
            <th>Time Point</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="endpoint in formattedEndpoints" 
            :key="endpoint.name"
            @click="selectEndpoint(endpoint.name)"
            class="clickable-row"
          >
            <td class="icon-column">
              <font-awesome-icon 
                :icon="endpoint.isSafety ? 'shield-heart' : 'bullseye'"
                :class="endpoint.isSafety ? 'safety-icon' : 'efficacy-icon'"
                :title="endpoint.isSafety ? 'Safety endpoints' : 'Efficacy endpoints'"
              />
            </td>
            <td class="endpoint-column">
              {{ endpoint.name }}
            </td>
            <td class="number">{{ formatNumber(endpoint.drug) }}</td>
            <td class="unit">{{ formatNumber(endpoint.drug) ? endpoint.unit : '' }}</td>
            <td class="number">{{ formatNumber(endpoint.placebo) }}</td>
            <td class="unit">{{ formatNumber(endpoint.placebo) ? endpoint.unit : '' }}</td>
            <td class="number">{{ formatNumber(endpoint.difference) }}</td>
            <td class="unit">{{ formatNumber(endpoint.difference) ? endpoint.unit : '' }}</td>
            <td>{{ endpoint.time }}</td>
          </tr>
        </tbody>
      </table>
      <p v-else class="no-data">No endpoints reported</p>

      <h3>Baselines</h3>
      <table v-if="formattedBaselines.length">
        <thead>
          <tr>
            <th>Endpoint</th>
            <th class="number">Drug</th>
            <th></th>
            <th class="number">Placebo</th>
            <th></th>
            <th class="number">Difference</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="baseline in formattedBaselines" :key="baseline.name">
            <td class="endpoint-column">{{ baseline.name }}</td>
            <td class="number">{{ formatNumber(baseline.drug) }}</td>
            <td class="unit">{{ formatNumber(baseline.drug) ? baseline.unit : '' }}</td>
            <td class="number">{{ formatNumber(baseline.placebo) }}</td>
            <td class="unit">{{ formatNumber(baseline.placebo) ? baseline.unit : '' }}</td>
            <td class="number">{{ formatNumber(baseline.difference) }}</td>
            <td class="unit">{{ formatNumber(baseline.difference) ? baseline.unit : '' }}</td>
          </tr>
        </tbody>
      </table>
      <p v-else class="no-data">No baselines reported</p>

      <h3>Demographics</h3>
      <table v-if="formattedDemographics.length">
        <thead>
          <tr>
            <th>Characteristic</th>
            <th class="number">Drug</th>
            <th></th>
            <th class="number">Placebo</th>
            <th></th>
            <th class="number">Difference</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="demo in formattedDemographics" :key="demo.name">
            <td class="endpoint-column">{{ demo.name }}</td>
            <td class="number">{{ formatNumber(demo.drug) }}</td>
            <td class="unit">{{ formatNumber(demo.drug) ? demo.unit : '' }}</td>
            <td class="number">{{ formatNumber(demo.placebo) }}</td>
            <td class="unit">{{ formatNumber(demo.placebo) ? demo.unit : '' }}</td>
            <td class="number">{{ formatNumber(demo.difference) }}</td>
            <td class="unit">{{ formatNumber(demo.difference) ? demo.unit : '' }}</td>
          </tr>
        </tbody>
      </table>
      <p v-else class="no-data">No demographics reported</p>

      <h3>Eligibility</h3>
      <table v-if="formattedEligibility.length">
        <thead>
          <tr>
            <th>Criterion</th>
            <th class="number">Value</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="criterion in formattedEligibility" :key="criterion.name">
            <td class="endpoint-column">{{ criterion.name }}</td>
            <td class="number eligibility-column">
              <ul v-if="Array.isArray(criterion.value)">
                <li v-for="item in criterion.value" :key="item">{{ item }}</li>
              </ul>
              <template v-else>{{ criterion.value }}</template>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-if="selectedRow?.eligibility?.comment" class="eligibility-comment">
        {{ selectedRow.eligibility.comment }}
      </p>
      <p v-else-if="!formattedEligibility.length" class="no-data">No eligibility criteria reported</p>
    </div>
  </div>
</template>

<script>
import { useDataStore } from '../stores/dataStore';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

export default {
  setup() {
    const dataStore = useDataStore();
    const { selectedRow } = storeToRefs(dataStore);
    
    // Add base URL constant
    const baseUrl = computed(() => {
      const isLocalhost = window.location.hostname === 'localhost';
      return isLocalhost ? 'http://localhost:5173' : 'https://annotator.gosset.ai';
    });

    // Modify validation link logic
    const validationToken = computed(() => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('token') || '';
    });

    const formatDataRow = (name, data, timeIndex = 0) => {
      // Add null checks for drug data
      if (!data?.drug?.values || !data?.meta) {
        return {
          name,
          drug: null,
          placebo: null,
          difference: null,
          unit: '',
          time: '',
          isSafety: false
        };
      }

      const drugValue = data.drug.values[timeIndex] ?? null;
      const placeboValue = data.placebo?.values?.[timeIndex] ?? null;
      const timePoint = data.meta.times?.[timeIndex];
      
      return {
        name,
        drug: drugValue,
        placebo: placeboValue,
        difference: drugValue !== null && placeboValue !== null 
          ? (drugValue - placeboValue).toFixed(2)
          : null,
        unit: data.meta.value_unit === 'percentage points' ? 'pp' 
          : data.meta.value_unit !== 'unitless' ? ` ${data.meta.value_unit}` : '',
        time: timePoint && !timePoint.startsWith('0') ? timePoint : '',
        isSafety: data.meta.safety || false
      };
    };

    // Add new function for baseline formatting
    const formatBaselineRow = (name, data) => {
      // Only require data and meta to exist
      if (!data?.meta) {
        return {
          name,
          drug: null,
          placebo: null,
          difference: null,
          unit: ''
        };
      }

      const drugValue = data.drug?.baseline_value ?? null;
      const placeboValue = data.placebo?.baseline_value ?? null;
      
      return {
        name,
        drug: drugValue,
        placebo: placeboValue,
        difference: drugValue !== null && placeboValue !== null 
          ? (drugValue - placeboValue).toFixed(2)
          : null,
        unit: data.meta.baseline_unit === 'percentage points' ? 'pp' 
          : data.meta.baseline_unit !== 'unitless' ? ` ${data.meta.baseline_unit}` : '',
      };
    };

    const cachedFormattedEndpoints = new Map();

    const getFormattedEndpoints = (row) => {
      const cacheKey = row?.result_id + '-' + row?.arm;
      if (!cacheKey) return [];
      
      if (cachedFormattedEndpoints.has(cacheKey)) {
        return cachedFormattedEndpoints.get(cacheKey);
      }
      
      const formatted = Object.entries(row.endpoints)
        .map(([name, data]) => {
          const lastTimeIndex = data.meta?.times?.length ? data.meta.times.length - 1 : 0;
          return formatDataRow(name, data, lastTimeIndex);
        })
        .filter(row => row && (
          row.drug !== null ||
          row.placebo !== null ||
          row.difference !== null
        ))
        .sort((a, b) => {
          if (a.isSafety !== b.isSafety) {
            return a.isSafety ? 1 : -1;
          }
          if (a.isSafety && b.isSafety) {
            const aHasTEAE = a.name.includes('TEAE');
            const bHasTEAE = b.name.includes('TEAE');
            if (aHasTEAE !== bHasTEAE) {
              return aHasTEAE ? -1 : 1;
            }
          }
          return a.name.localeCompare(b.name);
        });
      cachedFormattedEndpoints.set(cacheKey, formatted);
      return formatted;
    };

    const formattedEndpoints = computed(() => {
      return getFormattedEndpoints(selectedRow.value);
    });

    const formattedDemographics = computed(() => {
      if (!selectedRow.value?.demographics) return [];
      
      return Object.entries(selectedRow.value.demographics)
        .map(([name, data]) => formatBaselineRow(name, data))
//        .filter(row => row))
        .sort((a, b) => a.name.localeCompare(b.name));
    });

    const formattedBaselines = computed(() => {
      console.log('selectedRow.value.endpoints');
      if (!selectedRow.value?.endpoints) return [];
      console.log(selectedRow.value.endpoints);
      const baselines = Object.entries(selectedRow.value.endpoints)
        .map(([name, data]) => {
          return formatBaselineRow(name, data);
        })
        .filter(row => {
          // Filter out rows where all numeric values are zero
          return row && (
            Number(row.drug) !== 0 ||
            Number(row.placebo) !== 0 ||
            Number(row.difference) !== 0
          );
        })
        .sort((a, b) => a.name.localeCompare(b.name));
      console.log(baselines);
      return baselines;
    });

    const formattedEligibility = computed(() => {
      if (!selectedRow.value?.eligibility) return [];
      
      const criteria = [];
      Object.entries(selectedRow.value.eligibility).forEach(([key, value]) => {
        // Skip comment as it's handled separately
        if (key === 'comment') return;

        // Handle range values
        if (value && typeof value === 'object' && ('min' in value || 'max' in value)) {
          let rangeText = '';
          if ('min' in value && 'max' in value) {
            if (value.min === null && value.max !== null) {
              rangeText = `≤ ${value.max}`;
            } else if (value.min !== null && value.max === null) {
              rangeText = `≥ ${value.min}`;
            } else {
              rangeText = `${value.min} - ${value.max}`;
            }
          } else if ('min' in value && value.min !== null) {
            rangeText = `≥ ${value.min}`;
          } else if ('max' in value && value.max !== null) {
            rangeText = `≤ ${value.max}`;
          }
          if (rangeText) {  // Only add if we have a valid range text
            criteria.push({
              name: key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
              value: rangeText
            });
          }
        }
        // Handle boolean values
        else if (typeof value === 'boolean') {
          criteria.push({
            name: key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
            value: value ? 'Required' : 'Excluded'
          });
        }
        // Handle string values
        else if (typeof value === 'string') {
          criteria.push({
            name: key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
            value: value
          });
        }
        else if (Array.isArray(value)) {
          criteria.push({
            name: key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
            value: value
          });
        }
      });

      return criteria.sort((a, b) => a.name.localeCompare(b.name));
    });

    const formatNumber = (value) => {
      return value !== null ? Number(value).toFixed(1) : '';
    };

    const selectEndpoint = (endpointName) => {
      // Set the endpoint as Y variable and scroll to chart
      dataStore.setSelectedYVar(`${endpointName} diff`);
      
      // Scroll to chart - using setTimeout to ensure DOM is updated
        const chartElement = document.querySelector('.chart-wrapper');
        if (chartElement) {
          chartElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const closeDetails = () => {
      dataStore.setSelectedRow(null);
    };

    const formatDate = (dateString) => {
      if (!dateString) return '';
      if (dateString.length === 7) { // YYYY-MM format
        const [year, month] = dateString.split('-');
        return new Date(year, month - 1).toLocaleDateString(undefined, { year: 'numeric', month: 'short' });
      }
      return new Date(dateString).toLocaleDateString(undefined, { year: 'numeric', month: 'short' });
    };

    return {
      selectedRow,
      validationToken,
      baseUrl,
      formattedEndpoints,
      formattedDemographics,
      formattedBaselines,
      formattedEligibility,
      formatNumber,
      selectEndpoint,
      closeDetails,
      formatDate,
    };
  },
};
</script>

<style scoped>
.close-button {
  position: absolute;
  right: 1rem;
  top: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
  padding: 0.5rem;
  line-height: 1;
  border-radius: 50%;
}

.close-button:hover {
  background-color: #f0f0f0;
}

.details-component {
  padding: 1rem;
  position: relative;
}

h2{
    text-align: center;
}
h3 {
  font-size: 1.2rem;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  font-size: 0.875rem;
}

th, td {
  padding: 8px 12px;
  text-align: left;
  border: none;
}

th {
  color: #666;
  font-weight: 500;
  font-size: 0.75rem;
  text-transform: uppercase;
  padding-bottom: 16px;
}

tr:not(:last-child) td {
  border-bottom: 1px solid #f0f0f0;
}

tr:hover {
  background-color: #fafafa;
}

.number {
  text-align: right;  
  font-variant-numeric: tabular-nums;
  font-feature-settings: "tnum";
  padding-right: 2px; /* Add small right padding */
}

.unit {
  color: #666;
  padding-left: 2px;
  font-size: 0.75rem;
  width: 60px;  /* Add fixed width */
  min-width: 60px;  /* Ensure minimum width */
}

.no-data {
  color: #999;
  font-style: italic;
}

.icon-column {
  width: 30px;
  text-align: center;
  min-width: 30px; /* Add this to ensure fixed width */
}

.safety-icon {
  color: #4CB3D8; /* darker blue */
}

.efficacy-icon {
  color: #4CB3D8; /* darker blue */
}

/* Add these new styles */
.endpoint-column {
  width: 30%;
}
.eligibility-column {
  list-style-type: none;
}

.dose-info {
  text-align: center;
  color: #666;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.validation-link {
  display: block;
  text-align: center;
  font-size: 0.8rem;
  color: #666;
  text-decoration: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}

.validation-link:hover {
  text-decoration: underline;
}

.clickable-row {
  cursor: pointer;
}

.eligibility-comment {
  margin-top: 0.5rem;
  font-style: italic;
  color: #666;
}

.trial-info {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  margin: 1rem 0;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
}

.info-item {
  display: flex;
  flex-direction: column;
}

.info-item label {
  font-size: 0.75rem;
  color: #666;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}

.info-item span {
  font-size: 0.9rem;
}

.mechanisms, .sponsor {
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

.mechanisms label, .sponsor label {
  color: #666;
  margin-right: 0.5rem;
}

.source-link {
  display: inline-block;
  margin-top: 0.5rem;
  color: #4CB3D8;
  text-decoration: none;
  font-size: 0.9rem;
}

.source-link:hover {
  text-decoration: underline;
}

/* Add this new style */
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
</style>
