import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import router from './router'
import VueGtag from 'vue-gtag'

import { createPinia } from 'pinia'
import '@fortawesome/fontawesome-free/css/all.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faShieldHeart, faBullseye } from '@fortawesome/free-solid-svg-icons'
import './assets/common.css'

const pinia = createPinia()
const app = createApp(App)

// Add Vue Gtag configuration
app.use(VueGtag, {
  config: {
    id: 'G-5J5MCBXCW8'
  }
}, router)

app.use(router)
app.use(pinia)

// Add icons to library
library.add(faShieldHeart, faBullseye)

// Register FontAwesome component globally
app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app')
