<template>
  <div class="disease-view">
    <div class="toolbar">
        <h2 class="mb-0">
            <router-link to="/" class="gosset-link">
                <img src="@/assets/logo.png" alt="Gosset Logo" class="gosset-logo">
            </router-link> / {{ diseaseName }}
        </h2>
        <div class="toolbar-buttons">
          <div class="dropdown" @mouseleave="handleDropdownLeave" v-if="hasAnalyses">
                <button class="action-btn" @click="toggleAnalysesDropdown">
                    <i class="fas fa-list-alt"></i>
                    Explore Example Analyses
                </button>
                <div v-if="showAnalysesDropdown" class="dropdown-content">
                    <ul>
                        <li
                            v-for="analysis in dataStore.getAnalyses"
                            :key="analysis.name"
                            @click="applyAnalysis(analysis)"
                        >
                            {{ analysis.name }}
                        </li>
                    </ul>
                </div>
            </div>
            <button class="action-btn" @click="showColumnModal = true">
                <i class="fas fa-columns"></i>
                Column Visibility
            </button>
            <!--button class="action-btn" @click="downloadData">
                <i class="fas fa-download"></i>
                Export to CSV
            </button-->
            <button class="action-btn" @click="toggleDetailsPanel">
                <i class="fas fa-chart-line"></i>
                {{ isDetailsPanelVisible ? 'Hide Chart' : 'Show Chart' }}
            </button>
            
            <ColumnVisibilityModal 
                v-model:show="showColumnModal"
            />
        </div>
    </div>
    <div class="content">
      <div class="filter-panel">
        <FilterControls />
      </div>
      <div class="table-panel">
        <DataTable />
      </div>
      <div class="resize-handle" 
           v-if="isDetailsPanelVisible"
           :style="{ right: detailsPanelWidth + 'px' }" 
           @mousedown="startResize"></div>
      <div class="details-panel" 
           v-if="isDetailsPanelVisible"
           :style="{ width: detailsPanelWidth + 'px' }">
        <DetailsComponent />
        <ChartComponent />
        <TimeSeriesChartComponent v-if="showTimeSeriesChart" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from 'vue';
import { useDataStore } from '../stores/dataStore';
import { storeToRefs } from 'pinia';
import FilterControls from './FilterControls.vue';
import DataTable from './DataTable.vue';
import DetailsComponent from './DetailsComponent.vue';
import ChartComponent from './ChartComponent.vue';
import ColumnVisibilityModal from './ColumnVisibilityModal.vue';
import TimeSeriesChartComponent from './TimeSeriesChartComponent.vue';

export default {
  components: {
    FilterControls,
    DataTable,
    DetailsComponent,
    ChartComponent,
    ColumnVisibilityModal,
    TimeSeriesChartComponent,
  },
  props: {
    disease: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const dataStore = useDataStore();
    const { isLoading, error } = storeToRefs(dataStore);
    const detailsPanelWidth = ref(800);
    const isDetailsPanelVisible = ref(true);
    const showColumnModal = ref(false);
    const diseaseName = computed(() => dataStore.getDiseaseName);
    const showAnalysesDropdown = ref(false);

    const startResize = (e) => {
      e.preventDefault();
      const startX = e.clientX;
      const startWidth = detailsPanelWidth.value;

      const resize = (moveEvent) => {
        const diff = startX - moveEvent.clientX;
        detailsPanelWidth.value = Math.min(Math.max(300, startWidth + diff), 1400);
      };

      const stopResize = () => {
        window.removeEventListener('mousemove', resize);
        window.removeEventListener('mouseup', stopResize);
      };

      window.addEventListener('mousemove', resize);
      window.addEventListener('mouseup', stopResize);
    };

    const downloadData = () => {
      dataStore.exportToCSV();
    };

    const toggleDetailsPanel = () => {
      isDetailsPanelVisible.value = !isDetailsPanelVisible.value;
    };

    const showTimeSeriesChart = computed(() => {
      const diseaseParams = dataStore.config.diseaseParameters[dataStore.config.doid];
      return !!diseaseParams.timeSeriesVar;
    });

    const toggleAnalysesDropdown = () => {
      showAnalysesDropdown.value = !showAnalysesDropdown.value;
    };

    const handleDropdownLeave = () => {
      setTimeout(() => {
        showAnalysesDropdown.value = false;
      }, 100);
    };

    const applyAnalysis = (analysis) => {
      dataStore.applyAnalysis(analysis);
      showAnalysesDropdown.value = false;
    };

    const hasAnalyses = computed(() => {
      return dataStore.getAnalyses.length > 0;
    });

    onMounted(() => {
      dataStore.setDiseaseFromRoute(props.disease);
      dataStore.fetchData();
    });

    watch(() => props.disease, (newDisease) => {
      dataStore.setDiseaseFromRoute(newDisease);
      dataStore.fetchData();
    });

    watch(() => dataStore.selectedRow, (newValue) => {
      if (newValue && !isDetailsPanelVisible.value) {
        isDetailsPanelVisible.value = true;
      }
    });

    return {
      isLoading,
      error,
      detailsPanelWidth,
      startResize,
      downloadData,
      isDetailsPanelVisible,
      toggleDetailsPanel,
      showColumnModal,
      diseaseName,
      showTimeSeriesChart,
      showAnalysesDropdown,
      toggleAnalysesDropdown,
      handleDropdownLeave,
      applyAnalysis,
      dataStore,
      hasAnalyses,
    };
  }
};
</script>

<style scoped>
.disease-view {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.toolbar {
  height: 60px;
  padding: 0 1rem;
  background-color: rgb(248, 249, 250);
  border-bottom: 1px solid rgb(222, 226, 230);
  display: flex;
  align-items: center;
}

.gosset-link {
  display: inline-flex;
  align-items: center;
  height: 100%;
  margin-right: 0rem;
}

.gosset-logo {
	max-height: 24px;
	position: relative;
	top: 5px;
  width: auto;
  object-fit: contain;
}

.content {
  flex-grow: 1;
  display: grid;
  grid-template-columns: 300px minmax(0, 1fr) auto;
  height: calc(100vh - 60px);
  position: relative;
}

.filter-panel, .table-panel, .details-panel {
  overflow-y: auto;
  height: 100%;
}

.details-panel {
  padding: 1rem;
  transition: width 0.05s;
  overflow-y: auto;
  
  /* Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.05) transparent;
  transition: scrollbar-color 0.3s ease;
}

/* WebKit browsers */
.details-panel::-webkit-scrollbar {
  width: 20px;
}

.details-panel::-webkit-scrollbar-track {
  background: transparent;
}

.details-panel::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 5px solid transparent;
  background-clip: padding-box;
  transition: background-color 0.3s ease;
}

.details-panel:hover {
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  transition: scrollbar-color 0.3s ease;
}

.details-panel:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.details-panel:hover::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
}

.table-panel {
  padding: 0;
  display: flex;
  flex-direction: column;
}

h2 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: normal;

}

.chart-controls {
  margin-bottom: 1rem;
}

select {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.resize-handle {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  cursor: col-resize;
  background-color: #f4f4f4;
  transition: background-color 0.2s;
}

.resize-handle:hover {
  background-color: #d0d0d0;
}

.download-btn {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.download-btn:hover {
  background-color: #0056b3;
}

.toolbar-buttons {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding: 0;
    position: absolute;
    left: 300px;
    flex-wrap: wrap;
}

.action-btn {
    padding: 4px 12px;
    background-color: transparent;
    color: #0d6efd;
    border: 1px solid #0d6efd;
    border-radius: 4px;
    cursor: pointer;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
}

.action-btn:hover {
    background-color: rgba(13, 110, 253, 0.04);
    color: #0a58ca;
    border-color: #0a58ca;
}

.filter-panel {
  overflow-y: auto;
  height: 100%;
  
  /* Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.05) transparent;
  transition: scrollbar-color 0.3s ease;
}

/* Default scrollbar state - 95% transparent with transition */
.filter-panel::-webkit-scrollbar {
  width: 20px;
}

.filter-panel::-webkit-scrollbar-track {
  background: transparent;
}

.filter-panel::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 5px solid transparent;
  background-clip: padding-box;
  transition: background-color 0.3s ease;
}

/* Hover state - more visible */
.filter-panel:hover {
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  transition: scrollbar-color 0.3s ease;
}

.filter-panel:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.filter-panel:hover::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  z-index: 1000;
  background-color: #f9f9f9;
  min-width: 300px;
  margin-top: 0.25rem;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  top: 100%;
  left: 0;
  padding: 0.5rem 0;
}

.dropdown::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 10px;
  background: transparent;
}

.dropdown-content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-content li {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown-content li:hover {
  background-color: #f1f1f1;
}

/* Add this new style for the analyses button */
.dropdown .action-btn {
    background-color: #0d6efd;
    color: white;
}

.dropdown .action-btn:hover {
    background-color: #0a58ca;
    color: white;
    border-color: #0a58ca;
}
</style>
