<template>
  <div class="chart-container">
    <div v-if="dataStore.isLoading" class="loading-overlay">
      <div class="spinner"></div>
    </div>
    
    <div class="controls-grid">
      <div class="control-group">
        <div class="control-label">X Variable</div>
        <select v-if="dataStore.config" v-model="selectedXVar" @change="updateChart" class="form-select">
          <option v-for="xVar in sortedXVars" :key="xVar" :value="xVar">
            {{ dataStore.getDisplayName(xVar) }}
          </option>
        </select>
      </div>

      <div class="control-group">
        <div class="control-label">Y Variable</div>
        <select v-if="dataStore.config" v-model="selectedBaseYVar" @change="updateChart" class="form-select">
          <option v-for="yVar in sortedBaseYVars" :key="yVar" :value="yVar">
            {{ dataStore.getDisplayName(yVar) }}
          </option>
        </select>
      </div>

      <div class="control-group">
        <div class="control-label">Value</div>
        <select v-model="selectedValueType" @change="updateChart" class="form-select">
          <option value="diff">Drug - Placebo</option>
          <option value="drug">Drug</option>
          <option value="placebo">Placebo</option>
        </select>
      </div>

      <div class="control-group">
        <div class="control-label">Unit</div>
        <select v-if="availableUnits.length > 1" v-model="selectedUnit" @change="updateChart" class="form-select">
          <option v-for="unit in availableUnits" :key="unit" :value="unit">
            {{ unit }}
          </option>
        </select>
        <div v-else class="unit-display">
          {{ availableUnits[0] }}
        </div>
      </div>

      <div class="control-group">
        <div class="control-label">Type</div>
        <div class="chart-type-buttons">
          <button 
            v-for="type in availableChartTypes"
            :key="type"
            @click="updateChartType(type)"
            :class="['chart-type-icon', { active: selectedChartType === type }]"
            :title="getChartTypeTooltip(type)"
          >
            <ChartIcons :type="type" :icon-class="`${type}-icon`" />
          </button>
        </div>
      </div>

      <div class="control-group" v-if="['boxplot', 'bar'].includes(selectedChartType)">
        <div class="control-label">Sorting</div>
        <div class="sort-buttons">
          <button 
            v-for="sort in availableSortTypes"
            :key="sort"
            @click="updateSortType(sort)"
            :class="['sort-icon', { active: selectedSort === sort }]"
            :title="getSortTypeTooltip(sort)"
          >
            <ChartIcons :type="sort" :icon-class="`${sort}-icon`" />
          </button>
        </div>
      </div>
    </div>
    <div class="chart-wrapper" ref="chartWrapper">
      <canvas ref="chartCanvas"></canvas>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, watch, computed, onUnmounted } from 'vue';
import { useDataStore } from '../stores/dataStore';
import { storeToRefs } from 'pinia';
import { Chart, registerables } from 'chart.js';
import { BoxPlotController, BoxAndWiskers } from '@sgratzl/chartjs-chart-boxplot';
import ChartIcons from './ChartIcons.vue';

Chart.register(...registerables, BoxPlotController, BoxAndWiskers);

export default defineComponent({
  name: 'ChartComponent',
  components: {
    ChartIcons
  },
  setup() {
    const dataStore = useDataStore();
    const {
      selectedXVar,
      selectedBaseYVar,
      selectedValueType,
      selectedChartType,
      selectedSort,
      selectedUnit,
    } = storeToRefs(dataStore);

    const chartCanvas = ref(null);
    const chartWrapper = ref(null);
    let chart = null;
    let resizeObserver = null;

    const xVars = computed(() => dataStore.config?.xVars || []);
    const baseYVars = computed(() => {
      const yVars = dataStore.config?.yVars || [];
      return [...new Set(yVars.map(yVar => yVar.replace(/ (diff|drug|placebo)$/, '')))];
    });

    const selectedYVar = computed(() => {
      if (!selectedBaseYVar.value) return null;
      return `${selectedBaseYVar.value} ${selectedValueType.value}`;
    });

    watch([
      () => dataStore.config?.yVars,
      () => dataStore.selectedYVar
    ], ([newYVars, newSelectedYVar]) => {
      if (newSelectedYVar) {
        const baseVar = newSelectedYVar.replace(/ (diff|drug|placebo)$/, '');
        const valueType = newSelectedYVar.match(/ (diff|drug|placebo)$/)?.[1] || dataStore.defaultValueType;
        selectedBaseYVar.value = baseVar;
        selectedValueType.value = valueType;
        
        if (!selectedXVar.value) {
          selectedXVar.value = dataStore.defaultXVar;
        }
        
        updateChart();
      } else if (newYVars?.length > 0 && !selectedBaseYVar.value) {
        selectedBaseYVar.value = dataStore.config?.defaultYVar || baseYVars.value[0];
        selectedValueType.value = dataStore.defaultValueType;
        
        if (!selectedXVar.value) {
          selectedXVar.value = dataStore.defaultXVar;
        }
        
        updateChart();
      }
    }, { immediate: true });

    const getPointStyles = (isSelected) => ({
      backgroundColor: isSelected ? 'rgba(255, 99, 132, 0.6)' : 'rgba(255, 99, 132, 0.3)',
      borderColor: isSelected ? 'rgba(255, 99, 132, 1)' : 'rgba(255, 99, 132, 0.5)',
      borderWidth: isSelected ? 2 : 1,
      pointRadius: isSelected ? 6 : 3,
      // Add transition properties
      transition: 'all 0.3s ease',
    });

    const getBoxplotStyles = () => ({
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
    });

    const availableUnits = computed(() => {
      const units = new Set();
      const baseEndpoint = selectedBaseYVar.value;
      
      // Check for default unit in disease parameters
      const diseaseParams = dataStore.config.diseaseParameters[dataStore.config.doid];
      const defaultUnit = diseaseParams?.defaultUnits?.[baseEndpoint];
      
      // If there's a default unit, add it first
      if (defaultUnit) {
        units.add(defaultUnit);
      }

      const acceptedUnitsForEndpoint = dataStore.config.acceptedUnits[baseEndpoint];

      dataStore.filteredData.forEach(item => {
        const yData = item[selectedYVar.value];
        if (!yData) return;

        // Get the base endpoint name and metadata
        const baseEndpoint = selectedYVar.value.replace(/ (diff|drug|placebo)$/, '');
        const endpointMeta = item.endpoints?.[baseEndpoint]?.meta;
        const valueType = endpointMeta?.value_type;

        if (yData.unit) {
          // Handle percentage units based on value type
          if (yData.unit === '%') {
            if (yData.org_unit) {
              // If it has both unit and org_unit, it's multiplicative change
              units.add('% change');
            } else if (valueType === 'additive_change') {
              units.add('pp change');
            } else {
              units.add('%');
            }
          } else if (yData.unit === acceptedUnitsForEndpoint || yData.unit === defaultUnit) {
            units.add(yData.unit);
          }
        }
      });
      return Array.from(units);
    });

    const getItemValue = (item) => {
      const yData = item[selectedYVar.value];
      if (!yData) return null;

      // Get the base endpoint name and metadata
      const baseEndpoint = selectedYVar.value.replace(/ (diff|drug|placebo)$/, '');
      const endpointMeta = item.endpoints?.[baseEndpoint]?.meta;
      const valueType = endpointMeta?.value_type;

      // If selected unit is not a percentage type, show all data with that unit
      if (selectedUnit.value && !['%', '% change', 'pp change'].includes(selectedUnit.value)) {
        if (yData.org_unit === selectedUnit.value) {
          return yData.org_value;
        }
        if (yData.unit === selectedUnit.value) {
          return yData.value;
        }
        return null;
      }

      // Handle percentage values based on type
      if (yData.unit === '%') {
        const desiredUnit = selectedUnit.value;
        
        // Multiplicative change (has both unit and org_unit)
        if (yData.org_unit && desiredUnit === '% change') {
          return yData.value;
        }
        // Additive change
        else if (valueType === 'additive_change' && desiredUnit === 'pp change') {
          return yData.value;
        }
        // Regular percentage
        else if (!yData.org_unit && valueType !== 'additive_change' && desiredUnit === '%') {
          return yData.value;
        }
        return null;
      }

      return null;
    };

    const chartData = computed(() => {
      // Create a unique identifier for each data point using result_id and arm
      const uniqueData = dataStore.filteredData.reduce((acc, item) => {
        const key = `${item.result_id}-${item.arm}`;
        acc[key] = item;
        return acc;
      }, {});

      // Use the unique data for chart creation
      const data = Object.values(uniqueData);
      const groupedData = {};
      const selectedRow = dataStore.selectedRow;

      // Early return with empty datasets if no data
      if (!data || data.length === 0) {
        return {
          labels: [],
          datasets: [{
            data: [],
            label: ''
          }]
        };
      }

      // Filter data to only include rows where the selected value exists AND has valid time points
      const validData = data.filter(item => {
        const value = getItemValue(item);
        if (value == null) return false;

        // Get the base endpoint name (without diff/drug/placebo suffix)
        const baseEndpoint = selectedYVar.value.replace(/ (diff|drug|placebo)$/, '');
        
        // Get the endpoint metadata
        const endpointMeta = item.endpoints?.[baseEndpoint]?.meta;
        
        // Filter: Skip if the last time point starts with '0'
        if (endpointMeta?.times?.length) {
          const lastTimeIndex = endpointMeta.times.length - 1;
          if (endpointMeta.times[lastTimeIndex]?.startsWith('0')) return false;
        }

        return true;
      });

      if (selectedChartType.value === 'scatter') {
        const scatterData = validData.flatMap(item => {
          const value = getItemValue(item);
          if (value == null) return [];

          const xValues = Array.isArray(item[selectedXVar.value])
            ? item[selectedXVar.value]
            : [item[selectedXVar.value]];
          
          return xValues
            .filter(xValue => xValue != null)
            .map(xValue => ({
              x: dataStore.isContinuous(selectedXVar.value) && dataStore.isDate(selectedXVar.value) 
                ? new Date(xValue).getTime()
                : xValue,
              y: value,
              isSelected: selectedRow && item === selectedRow,
              originalRow: item
            }));
        });

        return {
          datasets: [{
            label: dataStore.getDisplayName(selectedYVar.value) || selectedYVar.value,
            data: scatterData,
            backgroundColor: (context) => getPointStyles(context.raw?.isSelected).backgroundColor,
            borderColor: (context) => getPointStyles(context.raw?.isSelected).borderColor,
            borderWidth: (context) => getPointStyles(context.raw?.isSelected).borderWidth,
            pointRadius: (context) => getPointStyles(context.raw?.isSelected).pointRadius,
          }]
        };
      }

      // Continue with existing logic using validData instead of data
      validData.forEach(item => {
        const value = getItemValue(item);
        if (value == null) return;

        // Get the base endpoint name (without diff/drug/placebo suffix)
        const baseEndpoint = selectedYVar.value.replace(/ (diff|drug|placebo)$/, '');
        
        // Check if this endpoint requires change values
        const requiresChange = dataStore.config.changeOnlyEndpoints.includes(baseEndpoint);
        
        // Get the endpoint metadata
        const endpointMeta = item.endpoints?.[baseEndpoint]?.meta;
        
        // Skip if the endpoint requires change values but the value_type isn't a change type
        if (requiresChange && 
            (!endpointMeta?.value_type || 
             !['multiplicative_change', 'additive_change'].includes(endpointMeta.value_type))) {
          return;
        }

        const xValues = Array.isArray(item[selectedXVar.value]) 
          ? item[selectedXVar.value].filter(x => x != null)
          : [item[selectedXVar.value]];
        
        // Skip if X value is null/undefined or empty array
        if (!xValues.length || xValues[0] == null) return;
        
        xValues.forEach(xValue => {
          // Convert boolean strings to actual booleans for eligibility variables
          if (selectedXVar.value.startsWith('eligibility_') && 
              (xValue === 'true' || xValue === 'false')) {
            xValue = xValue === 'true';
          }
          
          if (!groupedData[xValue]) {
            groupedData[xValue] = [];
          }
          groupedData[xValue].push({
            y: value,
            originalRow: item
          });
        });
      });

      let labels = Object.keys(groupedData);
      
      // Sorting logic
      if (['boxplot', 'bar'].includes(selectedChartType.value)) {
        if (selectedSort.value === 'x-asc') {
          labels.sort((a, b) => {
            const numA = !isNaN(a) ? parseFloat(a) : a;
            const numB = !isNaN(b) ? parseFloat(b) : b;
            
            if (typeof numA === 'number' && typeof numB === 'number') {
              return numA - numB;
            }
            return String(a).localeCompare(String(b));
          });
        } else {
          const medians = labels.map(key => {
            const values = groupedData[key]
              .map(item => {
                const yData = item.originalRow[selectedYVar.value];
                // Ensure we're using consistent values for sorting
                let value;
                if (yData?.org_value !== undefined && yData?.org_unit === selectedUnit.value) {
                  value = yData.org_value;
                } else if (yData?.value !== undefined) {
                  value = yData.value;
                } else {
                  return null;
                }
                return Number(value);
              })
              .filter(val => val !== null && !isNaN(val))
              .sort((a, b) => a - b);

            // Calculate mean instead of median for more stable sorting
            const mean = values.length > 0 
              ? values.reduce((sum, val) => sum + val, 0) / values.length 
              : 0;

            return {
              key,
              mean,
              count: values.length
            };
          });
          
          // Sort by mean value, prioritizing groups with data
          medians.sort((a, b) => {
            // Handle cases where one or both groups have no data
            if (a.count === 0 && b.count === 0) return 0;
            if (a.count === 0) return 1;
            if (b.count === 0) return -1;
            
            // Sort by mean value
            return selectedSort.value === 'y-asc' 
              ? a.mean - b.mean 
              : b.mean - a.mean;
          });
          
          labels = medians.map(item => item.key);
        }
      }

      let datasets;
      if (selectedChartType.value === 'boxplot') {
        // Check if there's any data before creating datasets
        if (Object.keys(groupedData).length === 0) {
          datasets = [{
            data: [],
            label: dataStore.getDisplayName(selectedYVar.value)
          }];
        } else {
          datasets = [
            {
              label: dataStore.getDisplayName(selectedYVar.value),
              data: labels.map(key => groupedData[key].map(item => item.y)),
              ...getBoxplotStyles()
            },
            {
              type: 'scatter',
              data: labels.map((label) => 
                groupedData[label].map(item => ({
                  x: label, 
                  y: item.y,
                  originalRow: item.originalRow,
                  isSelected: selectedRow && item.originalRow === selectedRow
                }))
              ).flat().filter(Boolean), // Add filter(Boolean) to remove any undefined items
              backgroundColor: (context) => context.raw?.isSelected ? getPointStyles(true).backgroundColor : getPointStyles(false).backgroundColor,
              borderColor: (context) => context.raw?.isSelected ? getPointStyles(true).borderColor : getPointStyles(false).borderColor,
              borderWidth: (context) => context.raw?.isSelected ? getPointStyles(true).borderWidth : getPointStyles(false).borderWidth,
              pointRadius: (context) => context.raw?.isSelected ? getPointStyles(true).pointRadius : getPointStyles(false).pointRadius,
            }
          ];
        }
      } else if (selectedChartType.value === 'bar') {
        datasets = [
          {
            label: dataStore.getDisplayName(selectedYVar.value),
            data: labels.map(key => 
              groupedData[key].reduce((sum, item) => sum + item.y, 0) / groupedData[key].length
            ),
            ...getBoxplotStyles()
          },
          {
            type: 'scatter',
            data: labels.map((label) => 
              groupedData[label].map(item => ({
                x: label, 
                y: item.y,
                originalRow: item.originalRow,  // Ensure originalRow is included
                isSelected: selectedRow && item.originalRow === selectedRow
              }))
            ).flat(),
            backgroundColor: (context) => getPointStyles(context.raw.isSelected).backgroundColor,
            borderColor: (context) => getPointStyles(context.raw.isSelected).borderColor,
            borderWidth: (context) => getPointStyles(context.raw.isSelected).borderWidth,
            pointRadius: (context) => getPointStyles(context.raw.isSelected).pointRadius,
          }
        ];
      }

      return { labels, datasets };
    });

    const createChart = () => {
      // Add null check for canvas element
      if (!chartCanvas.value || !chartWrapper.value) {
        console.warn('Canvas or wrapper element not ready');
        return;
      }

      const ctx = chartCanvas.value.getContext('2d');
      if (!ctx) {
        console.warn('Could not get canvas context');
        return;
      }

      const wrapperWidth = chartWrapper.value.clientWidth;
      const wrapperHeight = chartWrapper.value.clientHeight;
      
      chartCanvas.value.width = wrapperWidth;
      chartCanvas.value.height = wrapperHeight;

      chart = new Chart(ctx, {
        type: selectedChartType.value,
        data: chartData.value,
        options: {
          responsive: false,
          animation: false,
          onClick: (event, elements) => {
            if (!elements?.length) {
              dataStore.setSelectedRow(null);
              return;
            }

            const element = elements[0];
            // Add null checks and safely access properties
            const dataPoint = element?.element?.$context?.raw;
            
            if (!dataPoint?.originalRow) {
              return;
            }

            if (dataStore.selectedRow === dataPoint.originalRow) {
              dataStore.setSelectedRow(null);
            } else {
              dataStore.setSelectedRow(dataPoint.originalRow);
            }
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              filter: function(tooltipItem) {
                return tooltipItem.datasetIndex === 1;
              },
              callbacks: {
                title: () => null,
                label: function(context) {
                  const dataPoint = context.raw;
                  if (!dataPoint) return null;

                  let xValue = dataPoint.x;
                  let yValue = dataPoint.y;

                  // Round yValue to 1 decimal if it's numeric
                  if (typeof yValue === 'number') {
                    yValue = Number(yValue.toFixed(1));
                  }

                  // Format x value for eligibility variables
                  if (selectedXVar.value.startsWith('eligibility_') && 
                      (xValue === true || xValue === 'true' || xValue === false || xValue === 'false')) {
                    xValue = (xValue === true || xValue === 'true') ? 'Required' : 'Excluded';
                  }

                  // Format the tooltip text
                  const xLabel = dataStore.getDisplayName(selectedXVar.value);
                  const yLabel = dataStore.getDisplayName(selectedYVar.value);
                  
                  return [
                    `${xLabel}: ${xValue}`,
                    `${yLabel}: ${yValue}`
                  ];
                }
              }
            },
            title: {
              display: true,
              text: `${dataStore.getDisplayName(selectedYVar.value)} by ${dataStore.getDisplayName(selectedXVar.value)}`,
              font: {
                size: 20,
                weight: 'bold'
              },
              padding: {
                top: 10,
                bottom: 20
              }
            },
          },
          scales: {
            y: {    
              beginAtZero: true,
              title: {
                display: true,
                text: `${dataStore.getDisplayName(selectedYVar.value)}${
                  selectedUnit.value ? ` (${selectedUnit.value})` : ''
                }`,
                font: {
                  size: 16,
                }
              },
              ticks: {
                font: {
                  size: 14
                }
              }
            },
            x: {
              title: {
                display: true,
                text: dataStore.getDisplayName(selectedXVar.value),
                font: {
                  size: 16,
                }
              },
              ticks: {
                font: {
                  size: 14
                },
                callback: function(value) {
                  // Get the label text based on the chart type and value
                  let label;
                  if (selectedChartType.value === 'scatter') {
                    // Format dates if the x-axis contains date values
                    if (dataStore.isDate(selectedXVar.value)) {
                      const date = new Date(value);
                      label = date.toISOString().split('T')[0]; // Returns YYYY-MM-DD
                    } else {
                      label = value;
                    }
                  } else if (selectedXVar.value.startsWith('eligibility_')) {
                    // Handle eligibility boolean variables
                    if (value == true || value == 'true') {
                      label = 'Required';
                    } else if (value == false || value == 'false') {
                      label = 'Excluded';
                    } else {
                      label = "Undefined";
                    }
                  } else {
                    // For other chart types (boxplot, bar), use the labels array
                    label = this.getLabelForValue(value);
                  }

                  // Truncate label if longer than 32 characters
                  if (label && label.length > 32) {
                    return label.substring(0, 29) + '...';
                  }
                  return label;
                }
              },
              offset: true
            },
          },
        },
      });
    };

    const updateChart = () => {
      // Add null checks
      if (!chartCanvas.value || !chartWrapper.value) {
        console.warn('Canvas or wrapper element not ready');
        return;
      }

      // Add debouncing to prevent rapid updates
      if (chart) {
        window.requestAnimationFrame(() => {
          chart.destroy();
          createChart();
        });
      } else {
        createChart();
      }
    };

    onMounted(() => {
      // Add setTimeout to ensure DOM is ready
      setTimeout(() => {
        if (dataStore.getData.length === 0) {
          dataStore.fetchData();
        } else {
          createChart();
        }

        // Add error handling for ResizeObserver
        resizeObserver = new ResizeObserver((entries) => {
          window.requestAnimationFrame(() => {
            if (!entries.length) return;
            updateChart();
          });
        });
        
        if (chartWrapper.value) {
          resizeObserver.observe(chartWrapper.value);
        }
      }, 0);
    });

    onUnmounted(() => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
      if (chart) {
        chart.destroy();
      }
    });

    watch(() => dataStore.getData, () => {
      if (chart) {
        updateChart();
      } else {
        createChart();
      }
    }, { deep: true });

    // Add new watch for filteredData
    watch(() => dataStore.filteredData, () => {
      if (chart) {
        updateChart();
      } else {
        createChart();
      }
    }, { deep: true });

    // Add a watch for selectedRow
    watch(() => dataStore.selectedRow, () => {
      if (chart) {
        updateChart();
      }
    });

    watch(availableUnits, (newUnits) => {
      if (newUnits.length > 0 && !selectedUnit.value) {
        selectedUnit.value = newUnits[0];
        updateChart();
      }
    }, { immediate: true });

    watch([selectedYVar, availableUnits], ([, newUnits]) => {
      if (newUnits.length > 0) {
        // Check for default unit in disease parameters
        const baseEndpoint = selectedBaseYVar.value;
        const diseaseParams = dataStore.config.diseaseParameters[dataStore.config.doid];
        const defaultUnit = diseaseParams?.defaultUnits?.[baseEndpoint];
        
        // If there's a default unit and it's available, use it
        if (defaultUnit && newUnits.includes(defaultUnit)) {
          selectedUnit.value = defaultUnit;
        } 
        // Otherwise, if current unit isn't available, switch to first available unit
        else if (!newUnits.includes(selectedUnit.value)) {
          selectedUnit.value = newUnits[0];
        }
        updateChart();
      }
    }, { immediate: true });

    const availableChartTypes = computed(() => {
      // Check if current X variable is continuous
      const isContinuous = dataStore.isContinuous(selectedXVar.value);
      
      // Return all chart types if continuous, otherwise exclude scatter
      return isContinuous ? ['scatter'] : ['boxplot', 'bar'];
    });

    const getChartTypeTooltip = (type) => {
      const tooltips = {
        'boxplot': 'Box Plot',
        'bar': 'Bar Chart',
        'scatter': 'Scatter Plot'
      };
      return tooltips[type];
    };

    const updateChartType = (type) => {
      selectedChartType.value = type;
      updateChart();
    };

    const availableSortTypes = ['x-asc', 'y-asc', 'y-desc'];
    
    const getSortTypeTooltip = (sort) => {
      const tooltips = {
        'x-asc': 'Sort by X (A-Z)',
        'y-asc': 'Sort by Y (Low to High)',
        'y-desc': 'Sort by Y (High to Low)'
      };
      return tooltips[sort];
    };

    const updateSortType = (sort) => {
      selectedSort.value = sort;
      updateChart();
    };

    const sortedXVars = computed(() => {
      return [...xVars.value].sort((a, b) => 
        dataStore.getDisplayName(a).localeCompare(dataStore.getDisplayName(b))
      );
    });

    const sortedBaseYVars = computed(() => {
      return [...baseYVars.value].sort((a, b) => 
        dataStore.getDisplayName(a).localeCompare(dataStore.getDisplayName(b))
      );
    });

    watch(() => selectedXVar.value, (newXVar) => {
      // Check if current chart type is available for the new X variable
      const isContinuous = dataStore.isContinuous(newXVar);
      const currentTypeAvailable = isContinuous ? 
        ['scatter'].includes(selectedChartType.value) : 
        ['boxplot', 'bar'].includes(selectedChartType.value);

      // If current type isn't available, switch to first available type
      if (!currentTypeAvailable) {
        selectedChartType.value = isContinuous ? 'scatter' : 'boxplot';
      }
      
      updateChart();
    });

    return {
      chartCanvas,
      chartWrapper,
      selectedXVar,
      selectedBaseYVar,
      selectedValueType,
      selectedChartType,
      xVars,
      baseYVars,
      updateChart,
      dataStore,
      selectedSort,
      availableChartTypes,
      availableSortTypes,
      getChartTypeTooltip,
      updateChartType,
      getSortTypeTooltip,
      updateSortType,
      sortedXVars,
      sortedBaseYVars,
      selectedUnit,
      availableUnits,
    };
  },
});
</script>

<style scoped>
.chart-container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
}

.chart-wrapper {
  width: 100%;
/*  aspect-ratio: 1 / 1;*/
  max-height: 75vh;
    height: 75vh;
}

.controls-grid {
  display: grid;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  max-width: 1400px;
  width: 100%;
  /* Default for very small screens */
  grid-template-columns: 1fr;
}

/* Extra small screens */
@media (min-width: 380px) {
  .controls-grid {
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  }
}

/* Medium screens */
@media (min-width: 768px) {
  .controls-grid {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
}

/* Large screens */
@media (min-width: 1024px) {
  .controls-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

.control-group {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  min-width: 0;
}

.control-label {
  font-size: 0.75rem;
  font-weight: 700;
  color: #666;
  text-transform: uppercase;
  margin-bottom: 2px;
}

select {
  margin: 0;
  width: 100%;
  min-width: 120px;
  max-width: 200px;
  height: 32px;
  padding: 4px 8px;
  padding-right: 24px;
  font-size: 1rem;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 4px center;
  background-size: 16px;
}

.chart-type-buttons {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.chart-type-icon {
  padding: 4px;
  height: 32px;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
}

.chart-type-icon svg {
  width: 24px;
  height: 24px;
  fill: currentColor;
}

.chart-type-icon.active {
  color: #3498db;
}

.chart-type-icon:hover:not(.active) {
  color: #2980b9;
}

.sort-buttons {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.sort-icon {
  padding: 4px;
  height: 32px;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
}

.sort-icon svg {
  width: 24px;
  height: 24px;
  fill: currentColor;
}

.sort-icon.active {
  color: #3498db;
}

.sort-icon:hover:not(.active) {
  color: #2980b9;
}

.chart-wrapper canvas {
  cursor: pointer;
}

/* Add transitions for chart controls */
.chart-type-icon, .sort-icon {
  transition: color 0.2s ease, transform 0.2s ease;
}

.chart-type-icon:hover, .sort-icon:hover {
  transform: scale(1.1);
}

.chart-type-icon.active, .sort-icon.active {
  transform: scale(1.1);
  transition: color 0.2s ease, transform 0.2s ease;
}

/* Add transition for form controls */
select {
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

select:hover {
  border-color: #3498db;
}

select:focus {
  border-color: #3498db;
  outline: none;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.unit-display {
  padding: 4px 8px;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #666;
}
</style>

