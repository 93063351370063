// src/store/dataStore.js

import { defineStore } from 'pinia';
import axios from 'axios';
import { flattenObject } from '../utils/csvExport';

// eslint-disable-next-line no-unused-vars
const today = new Date().toISOString().split('T')[0];
const fiveYearsAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 5)).toISOString().split('T')[0];

  // Helper function for calculating difference

export const useDataStore = defineStore('data', {
  state: () => ({
    config: {
      doid: 'DOID_9970',
      diseaseMapping: {
        'obesity': 'DOID_9970',
        'atopic_dermatitis': 'DOID_3310',
        'hfpef': 'DOID_9775',
        'nsclc': 'DOID_3908',
        'itp': 'DOID_8924',
        'breast_cancer': 'DOID_1612',
        'adhd': 'DOID_1094',
        'dm1': 'DOID_11722',
        'dme': 'DOID_9191',
        'sepsis': 'DOID_0040085',
        'ded': 'DOID_12895',
        'cp': 'DOID_1969',
        'tgct': 'DOID_314',
      },
      diseaseParameters: {
        'DOID_1969': {
          'name': 'Cerebral Palsy',
          'defaultYVar': 'OSDI',
          'defaultXVar': 'drug_name',
          'defaultValueType': 'drug',
          'extraFilters': [
          ],
        },
        'DOID_314': {
          'name': 'TGCT',
          'defaultYVar': 'RECIST',
          'defaultXVar': 'drug_name',
          'defaultValueType': 'drug',
          'extraFilters': [
          ],
        },
        'DOID_12895': {
          'name': 'Dry eye disease',
          'defaultYVar': 'OSDI',
          'defaultXVar': 'drug_name',
          'defaultValueType': 'drug',
          'extraFilters': [
            { var: "eligibility_prior_eye_surgery", type: "boolean", group: "population" },
            { var: "eligibility_contact_lens_use", type: "boolean", group: "population" },
            { var: "OSDI diff", type: "range", displayName: "OSDI change (%)", defaultValue: { min: -300, max: 300 } },
          ],
        },
        'DOID_11722': {
          'name': 'DM1',
          'defaultYVar': 'FVC',
          'defaultXVar': 'drug_name',
          'defaultValueType': 'drug',
        },
        'DOID_9191': {
          'name': 'DME',
          'defaultYVar': 'BCVA',
          'defaultXVar': 'drug_name',
          'defaultValueType': 'drug',
          'defaultUnits': {
            'BCVA': 'logMAR',
          },
          'extraFilters': [
          ],
        },
        'DOID_0040085': {
          'name': 'Sepsis',
          'defaultYVar': '90-day mortality',
          'defaultXVar': 'drug_name',
          'defaultValueType': 'drug',
          'extraFilters': [
            { var: "eligibility_immunocompromised", type: "boolean", group: "population" },
            { var: "eligibility_infection_confirmed", type: "boolean", group: "population" },
            { var: "eligibility_prior_antibiotics", type: "boolean", group: "population" },
            { var: "eligibility_organ_dysfunction", type: "boolean", group: "population" },
          ],
        },
        'DOID_9970': {
          'name': 'Obesity',
          'defaultYVar': 'Body weight',
          'defaultValueType': 'diff',
          'defaultXVar': 'mechanisms',
          'timeSeriesVar': 'Body weight',
          'extraFilters': [
            { var: "eligibility_type2_diabetes", type: "boolean", group: "population" },
            { var: "eligibility_cardiovascular", type: "boolean", group: "population" },
            { var: "Body weight diff", type: "range", displayName: "Body weight change (%)" },
          ],
          'presetFilters': {
            publication_date: { min: fiveYearsAgo, max: today },
          },
          'analyses': [
            {
              name: 'Phase 3 weight loss',
              filters: {
                phase: ['3'],
                'Body weight diff': { min: -30, max: -5 },
              },
              chart: {
                selectedXVar: 'mechanisms',
                selectedBaseYVar: 'Body weight',
                selectedValueType: 'diff',
                selectedChartType: 'boxplot',
                selectedUnit: '%',
                selectedSort: 'y-desc',
              },
            }
          ]
        },
        'DOID_3310': {
          'name': 'Atopic Dermatitis',
          'defaultYVar': 'IGA 0/1',
          'defaultUnits': {
            'PFS': 'months',
            'DFS': 'months',
          },
          'extraFilters': [
            { var: "topical", type: "boolean", group: "population" },
          ],
          'analyses': [
            {
              name: 'JAK inhibitors',
              filters: {
                mechanisms: [
                  'Janus kinase 1 (JAK1) (inhibitor)',
                  'Janus kinase 1/3 (JAK1/3) (inhibitor)',
                  'Janus kinase 2 (JAK2) (inhibitor)',
                  'Janus kinase family (JAK) (inhibitor)',
                ],
                phase: ['2', '3', '4'],
                publication_date: null,
              },
              chart: {
                selectedXVar: 'drug_name',
                selectedBaseYVar: 'IGA 0/1',
                selectedValueType: 'diff',
                selectedChartType: 'boxplot',
                selectedUnit: '%',
                selectedSort: 'y-desc',
              },
            }
          ]
        },
        'DOID_9775': {
          'name': 'HFpEF', 
          'defaultYVar': 'All-cause hospitalization',
          'defaultValueType': 'drug',
          'defaultXVar': 'drug_name',
          'extraFilters': [
            { var: "eligibility_structural_heart_disease", type: "boolean", group: "population" },
            { var: "eligibility_atrial_fibrilation", type: "boolean", group: "population" },
            { var: "eligibility_chronic", type: "boolean", group: "population", displayName: "Eligibility Chronic Heart Failure" },
          ],
        },
        'DOID_3908': {
          'name': 'NSCLC', 
          'defaultYVar': 'ORR drug',
          'extraFilters': [
            { var: "eligibility_stages", type: "multichoice", style: "checkbox", group: "population", displayName: "Stage" },
            { var: "line_of_treatment", type: "multichoice", style: "checkbox", group: "population", displayName: "Line of treatment" },
            { var: "monotherapy", type: "boolean", group: "population", displayName: "Monotherapy" },

            { var: "eligibility_alk", type: "boolean", group: "population", displayName: "ALK" },
            { var: "eligibility_ros1", type: "boolean", group: "population", displayName: "ROS1" },
            { var: "eligibility_braf_v600e", type: "boolean", group: "population", displayName: "BRAF V600E" },
            { var: "eligibility_kras_g12c", type: "boolean", group: "population", displayName: "KRAS G12C" },
            { var: "eligibility_met_exon_skipping", type: "boolean", group: "population", displayName: "MET exon skipping" },
            { var: "eligibility_ret", type: "boolean", group: "population", displayName: "RET" },
            { var: "eligibility_her2", type: "boolean", group: "population", displayName: "HER2" },
            { var: "eligibility_pd_l1", type: "boolean", group: "population", displayName: "PD-L1" },

            { var: "eligibility_egfr", type: "boolean", group: "population", displayName: "eGFR" },
            { var: "eligibility_egfr_exon_19", type: "boolean", group: "population", displayName: "eGFR exon 19" },
            { var: "eligibility_egfr_l858r", type: "boolean", group: "population", displayName: "eGFR L858R" },
            { var: "eligibility_egfr_exon_20_insertion", type: "boolean", group: "population", displayName: "eGFR exon 20 insertion" },

            { var: "eligibility_ntrk", type: "boolean", group: "population", displayName: "NTRK" },
          ],
          'presetFilters': {
            publication_date: { min: fiveYearsAgo, max: today },
          },
          analyses: [
            {
              name: 'Phase 3 trials in stages III-IV',
              filters: {
                phase: ['3'],
                eligibility_stages: ['III','IV'],
              },
              chart: {
                selectedXVar: 'drug_name',
                selectedBaseYVar: 'ORR drug',
                selectedValueType: 'diff',
                selectedChartType: 'boxplot',
                selectedUnit: '%',
                selectedSort: 'y-desc',
              },
            }
          ]
        },
        'DOID_8924': {
          'name': 'ITP', 
          'defaultYVar': 'OR',
          'defaultValueType': 'drug',
          'defaultXVar': 'drug_name',
          'extraFilters': [
            { var: "eligibility_chronic_disease", type: "boolean", group: "population" },
            { var: "eligibility_splenectomy", type: "boolean", group: "population" },
            { var: "eligibility_pregnancy", type: "boolean", group: "population" },
            { var: "eligibility_failed_previous_treatment", type: "boolean", group: "population" },
            { var: "eligibility_persistent_disease", type: "boolean", group: "population" },
          ],
          'analyses': [
          {
            name: 'Late stage trials',
            filters: {
              phase: ['2', '3', '4'],
            },
            chart: {
              selectedXVar: 'phase',
              selectedBaseYVar: 'DCMPR',
              selectedValueType: 'diff',
              selectedChartType: 'boxplot',
              selectedUnit: '%',
              selectedSort: 'y-desc',
            },
          }
        ]},
        'DOID_1612': {'name': 'Breast Cancer', 'defaultYVar': 'ORR'},
        'DOID_1094': {'name': 'ADHD', 'defaultYVar': 'ADHD-RS-5'}
      },
      // Remove hardcoded yVars
      yVars: [], // This will be populated dynamically
      xVars: [
        "mechanisms",
        "drug_name",
        "phase",
        "fda_any",
        "fda_specific",
        "study_duration",
        "sponsor",
        "completion_date",
        "publication_date",
        "num_patients",
        "num_sites",
        "num_countries",
      ],
      variableDisplayNames: {
          "mechanisms": "Mechanism of Action",
          "n": "Number of Patients",
          "num_sites": "Number of sites",
          "num_countries": "Number of countries",
          "num_patients": "Number of patients (study)",
          "study_duration": "Study Duration",
          "washout": "Washout period",
          "source_type": "Source type",
          "trial": "Trials",

          "eligibility_type2_diabetes": "Has diabetes",
          "eligibility_cardiovascular": "Has a cardiovascular disease",

          "fda_any": "FDA approved (some indication)",
          "fda_specific": "FDA approved (this indication)",
          "other_names": "Other drug names"
      },
      variableType: {
        "publication_date": "date",
        "completion_date": "date",
        "mechanisms": "array",
        "sponsor": "array",
        "num_sites": "int",
        "num_countries": "int",
        "num_patients": "int",
        "study_duration": "float",
        "fda_any": "string",
        "fda_specific": "string",
        "Body weight diff": "float",
        "OSDI diff": "float",
      },
      filters: [
        // Add population filters first
        // These will be automatically populated from diseaseParameters.extraFilters
        // during data fetch
        
        { var: "publication_date", type: "range" },
        { var: "phase", type: "multichoice", style: "checkbox" },
        { var: "mechanisms", type: "multichoice", style: "select" },
        { var: "source_type", type: "multichoice", style: "checkbox" },
        { var: "completion_date", type: "range" },
        { var: "drug_name", type: "multichoice", style: "select" },
        { var: "sponsor", type: "multichoice", style: "select" },
        { var: "fda_any", type: "multichoice", style: "checkbox" },
        { var: "fda_specific", type: "multichoice", style: "checkbox" },
        { var: "num_patients", type: "range" },
        { var: "num_sites", type: "range" },
        { var: "num_countries", type: "range" },
      ],
      acceptedUnits: {
        "Age": "years",
        "Age (sd)": "years",
        "Age onset": "years",
        "Age onset (sd)": "years",
        "Male": "%",
        "White": "%",
        "Caucasian": "%",
        "Black": "%",
        "Asian": "%",
        "Hispanic": "%",
        "Other ethnicity": "%",
    
        "Body weight": "kg",
        "BMI": "kg/m^2",
        "Waist circumference": "cm",
        "PANSS total": "points",
        "PANSS negative": "points",
        "PANSS positive": "points",
        "CGI-S": "points",
        "Weight loss > 5%": "%",
        "Weight loss > 10%": "%",
        "Weight loss > 15%": "%",
        "Weight loss > 20%": "%",
        "Hemoglobine A1c": "%",
        "Body fat mass": "kg",
        "Lean body mass": "kg",
        "Fat free mass": "kg",
        "Systolic blood pressure": "mmHg",
        "Fasting insulin": "μIU/mL",
        "Total cholesterol": "mg/dL",
        "LDL cholesterol": "mg/dL",
        "HDL cholesterol": "mg/dL",

        "PFS": "months",
        "DFS": "months",
    
        "Any TEAE": "%",
        "TEAE causing discontinuation": "%",
        "Serious TEAE": "%",
        "Severe TEAE": "%",
        "Headache": "%",
        "Weight Gain": "kg",
        "Weight Gain > 7%": "%",
        "Nausea": "%",
        "Somnolence": "%",
        "Extrapyramidal Symptoms (EPS)": "%",
        "Diarrhea": "%",
        "Vomiting": "%",
        "Constipation": "%",
        "Dyspepsia": "%",

        "BCVA": "logMAR",

        "6MWD": "m",
      },
      defaultVisibleColumns: [
        "drug_name",
        "dose",
        "publication_date",
        "source_type",
        "trial",
        "phase",
        "mechanisms",
        "Any TEAE drug",
        "Serious TEAE drug",
        "Severe TEAE drug",
      ],
      // Add new configuration for endpoints that require change values
      changeOnlyEndpoints: [
        "Body weight",
        "OSDI",
        "Fasting insulin",
        "BMI",
        "Total cholesterol",
        "HDL cholesterol",
        "LDL cholesterol",
        "Waist circumference",
        "Fat free mass",
        "Systolic blood pressure",
      ],
      // Add default sorting configuration
      defaultSort: {
        column: "publication_date",
        order: "desc"
      },
      // Add default X variable configuration
      defaultXVar: "mechanisms",
      defaultValueType: "diff",
    },
    activeFilters: [],
    filterValues: {}, // Will store selected values for multichoice filters
    filterRanges: {},
    filterOptions: {},
    showScatterPoints: false,
    data: [],
    loading: false,
    error: null,
    selectedRow: null,
    selectedRanges: {}, // This will store the currently selected ranges
    selectedYVar: null,
    visibleColumns: [], // Add this new state property
    selectedXVar: null,

    // New state properties for chart settings
    selectedBaseYVar: null,
    selectedValueType: 'diff',
    selectedChartType: 'boxplot',
    selectedSort: 'y-desc',
    selectedUnit: null,
  }),

  actions: {
    async fetchData() {
      this.loading = true;
      this.error = null;

      try {
        const apiUrl = `https://api.gosset.ai/results/v2/?doid=${this.config.doid}`;
        const response = await axios.get(apiUrl);
        
        // Collect all unique endpoint names across all data points
        const endpointNames = new Set();
        response.data.forEach(entry => {
          if (entry.endpoints) {
            Object.keys(entry.endpoints).forEach(endpoint => {
              endpointNames.add(endpoint);
            });
          }
        });

        // Convert Set to Array and generate yVars
        this.config.yVars = Array.from(endpointNames).flatMap(endpoint => [
          `${endpoint} diff`,
          `${endpoint} drug`,
          `${endpoint} placebo`
        ]);

        // Update variableDisplayNames with all endpoint variables
        Array.from(endpointNames).forEach(endpoint => {
          // Add the base endpoint name first
          this.config.variableDisplayNames[endpoint] = endpoint.replace(/_/g, ' ');
          
          // Add the variants with their display names
          this.config.variableDisplayNames[`${endpoint} drug`] = `${endpoint} (Drug)`;
          this.config.variableDisplayNames[`${endpoint} placebo`] = `${endpoint} (Placebo)`;
          this.config.variableDisplayNames[`${endpoint} diff`] = `${endpoint} (Placebo-adjusted)`;
        });

        // Set default Y variable based on disease parameters
        const defaultYVar = this.config.diseaseParameters[this.config.doid].defaultYVar;
        if (defaultYVar && !this.selectedYVar) {
          this.selectedYVar = defaultYVar;
        }

        // Process the data
        this.data = response.data.map(entry => {
          const sourceTypeMap = {
            'ctg': 'Clinicaltrials.gov',
            'pubmed': 'Publication',
            'conference': 'Conference',
            'company': 'Company materials'
          };

          const processedEntry = {
            ...entry,
            source_type: entry.origin ? (sourceTypeMap[entry.origin] || 'Other') : 'No results found',
          };

          // Process endpoints with values and units
          if (entry.endpoints) {
            Object.entries(entry.endpoints).forEach(([endpointName, endpointData]) => {
              const lastTimeIndex = (endpointData.meta.times?.length || 1) - 1;
              
              const processValue = (value, baselineValue) => {
                if (!endpointData.meta.value_unit?.includes('%') && 
                    endpointData.meta.value_type === "additive_change" &&
                    baselineValue != null && 
                    Math.abs(baselineValue) > 0.0001 &&
                    endpointData.meta.value_unit === endpointData.meta.baseline_unit) {
                  const res = {
                    value: (value / baselineValue) * 100,
                    unit: '%',
                    org_value: value,
                    org_unit: endpointData.meta.value_unit
                  }
                  return res;
                }
                return {
                  value: value,
                  unit: endpointData.meta.value_unit
                };
              };

              // Store drug values and units
              const drugValue = endpointData.drug?.values?.[lastTimeIndex] ?? null;
              const drugBaseline = endpointData.drug?.baseline_value ?? null;
              const placeboBaseline = endpointData.placebo?.baseline_value ?? null;

              processedEntry[`${endpointName} drug`] = processValue(drugValue, drugBaseline);

              // Store placebo values and units
              const placeboValue = endpointData.placebo?.values?.[lastTimeIndex] ?? null;
              processedEntry[`${endpointName} placebo`] = processValue(placeboValue, placeboBaseline);

              // Calculate difference
              if (drugValue != null && placeboValue != null) {
                processedEntry[`${endpointName} diff`] = {
                  value: processedEntry[`${endpointName} drug`].value - processedEntry[`${endpointName} placebo`].value,
                  unit: processedEntry[`${endpointName} drug`].unit || endpointData.meta.value_unit,
                  org_value: processedEntry[`${endpointName} drug`].org_value !== undefined ? 
                    processedEntry[`${endpointName} drug`].org_value - processedEntry[`${endpointName} placebo`].org_value :
                    null,
                  org_unit: processedEntry[`${endpointName} drug`].org_unit || null
                };
              } else {
                processedEntry[`${endpointName} diff`] = {
                  value: null,
                  unit: processedEntry[`${endpointName} drug`]?.unit || endpointData.meta.value_unit,
                  org_value: null,
                  org_unit: processedEntry[`${endpointName} drug`]?.org_unit || null
                };
              }
            });
          }

          // Add eligibility criteria processing
          if (entry.eligibility) {
            Object.entries(entry.eligibility).forEach(([key, value]) => {
              if (key === 'comment') return;
              
              // For range values, add min and max as separate variables
              if (value && typeof value === 'object' && ('min' in value || 'max' in value)) {
                if ('min' in value) {
                  processedEntry[`eligibility_${key}_min`] = value.min;
                }
                if ('max' in value) {
                  processedEntry[`eligibility_${key}_max`] = value.max;
                }
              }
              // For boolean and string values, add directly
              else if (typeof value === 'boolean' || typeof value === 'string' || Array.isArray(value)) {
                let normalizedValue;
                if (typeof value === 'boolean' || Array.isArray(value)) {
                  normalizedValue = value;
                } else if (typeof value === 'string') {
                  const lowerValue = value.trim().toLowerCase();
                  if (lowerValue === 'yes' || lowerValue === 'y' || lowerValue === 'true') {
                    normalizedValue = true;
                  } else if (lowerValue === 'no' || lowerValue === 'n' || lowerValue === 'false') {
                    normalizedValue = false;
                  } else {
                    normalizedValue = null;
                  }
                }
                if (normalizedValue !== null) {
                  processedEntry[`eligibility_${key}`] = normalizedValue;
                }
              }
            });
          }
          // Process demographics with baseline values and units
          if (entry.demographics) {
            Object.entries(entry.demographics).forEach(([key, data]) => {
              processedEntry[`demographics_${key}`] = {
                value: data.baseline_value ?? null,
                unit: data.meta.value_unit ?? null
              };
            });
          }

          return processedEntry;
        });
        
        this.calculateDateRanges();

        // Initialize visible columns after data is loaded
        if (!this.visibleColumns.length) {
          this.initializeVisibleColumns();
        }

        // Calculate ranges for dynamically created diff variables that have filters
        this.config.changeOnlyEndpoints
          .filter(endpoint => this.config.filters.some(f => f.var === `${endpoint} diff`))
          .forEach(endpoint => {
            const diffVar = `${endpoint} diff`;
            const values = this.data
              .map(item => item[diffVar]?.value)
              .filter(val => val !== null && val !== undefined && !isNaN(val))
              .map(Number); // Convert all values to numbers
              
            if (values.length > 0) {
              this.filterRanges[diffVar] = {
                min: Number(Math.min(...values).toFixed(0))-1,
                max: Number(Math.max(...values).toFixed(0))+1
              };
            }
          });

        // Add population filters BEFORE calculating options
        // Add population filters to main filters array
        const diseaseParams = this.config.diseaseParameters[this.config.doid];
        if (diseaseParams?.extraFilters) {
          // Remove any existing filters that match extraFilters
          const extraFilterVars = diseaseParams.extraFilters.map(f => f.var);
          this.config.filters = this.config.filters.filter(f => !extraFilterVars.includes(f.var));
          
          // Add population filters at the start of the filters array
          this.config.filters.unshift(...diseaseParams.extraFilters);
        }

        // Add population filters to xVars if they exist
        if (diseaseParams?.extraFilters) {
          const populationVars = diseaseParams.extraFilters.map(filter => filter.var);
          this.config.xVars = [...new Set([...this.config.xVars, ...populationVars])];
        }

        // THEN calculate ranges and options for all filters
        this.config.filters.forEach(filter => {
          const values = this.data
            .map(item => item[filter.var])
            .filter(val => val !== null && val !== undefined);

          if (filter.type === 'multichoice') {
            let uniqueValues = new Set();
            values.forEach(val => {
              if (Array.isArray(val)) {
                val.forEach(v => uniqueValues.add(v));
              } else if (val !== undefined && val !== null) {
                uniqueValues.add(val);
              }
            });
            this.filterOptions[filter.var] = Array.from(uniqueValues).sort();
          } else if (filter.type === 'range') {
            const numericValues = values.map(Number).filter(val => !isNaN(val));
            if (numericValues.length > 0) {
              this.filterRanges[filter.var] = {
                min: Math.min(...numericValues),
                max: Math.max(...numericValues)
              };
            }
          }
        });

        // After calculating ranges and options for filters, initialize selected values
        this.config.filters.forEach(filter => {
          if (filter.type === 'range' && filter.defaultValue) {
            // Initialize range filter with default value
            this.selectedRanges[filter.var] = {
              min: filter.defaultValue.min ?? null,
              max: filter.defaultValue.max ?? null
            };
          } else if (filter.type === 'multichoice' && filter.defaultValue) {
            // Initialize multichoice filter with default value
            this.filterValues[filter.var] = Array.isArray(filter.defaultValue) 
              ? filter.defaultValue 
              : [filter.defaultValue];
          }
        });
      } catch (error) {
        this.error = 'Failed to fetch data';
        console.error('Error fetching data:', error);
      } finally {
        this.loading = false;
      }
    },

    calculateDateRanges() {
      const dateFilters = this.config.filters.filter(f => f.type === 'range' && this.config.variableType[f.var] === 'date');
      const diseaseParams = this.config.diseaseParameters[this.config.doid];
      
      dateFilters.forEach(filter => {
        const values = this.data
          .map(row => row[filter.var])
          .filter(date => date && !isNaN(new Date(date).getTime()))
          .map(date => new Date(date));

        // Set the full available range
        if (values.length > 0) {
          this.filterRanges[filter.var] = {
            min: new Date(Math.min(...values)),
            max: new Date(Math.max(...values))
          };
        } else {
          // Default range if no values found
          const today = new Date();
          const lastYear = new Date();
          lastYear.setFullYear(today.getFullYear() - 1);
          this.filterRanges[filter.var] = {
            min: lastYear,
            max: today
          };
        }

        // Set selected range based on disease presets
        if (diseaseParams?.presetFilters?.[filter.var]) {
          this.selectedRanges[filter.var] = {
            min: new Date(diseaseParams.presetFilters[filter.var].min),
            max: new Date(diseaseParams.presetFilters[filter.var].max)
          };
        } else {
          // No presets, initialize as null
          this.selectedRanges[filter.var] = {
            min: null,
            max: null
          };
        }
      });

      // Initialize multichoice filters using disease presets
      this.config.filters
        .filter(f => f.type === 'multichoice' && diseaseParams?.presetFilters?.[f.var])
        .forEach(filter => {
          this.filterValues[filter.var] = diseaseParams.presetFilters[filter.var];
        });
    },

    updateDateRange(variable, { min, max }) {
      this.selectedRanges[variable] = { min, max };
    },

    setShowScatterPoints(value) {
      this.showScatterPoints = value;
    },
    setSelectedRow(row) {
      this.selectedRow = row;
    },

    // Add new action for updating multichoice filter values
    updateFilterValues(filterVar, values) {
      this.filterValues[filterVar] = values;
    },

    updateRange(variable, range) {
      this.selectedRanges[variable] = {
        min: range.min !== null ? Number(range.min) : null,
        max: range.max !== null ? Number(range.max) : null
      };
    },

    setSelectedYVar(yVar) {
      this.selectedYVar = yVar;
    },

    exportToCSV() {
      // Get visible columns from the DataTable
      const visibleColumns = [...new Set([
        ...this.visibleColumns,
        ...this.getActiveFilterColumns(),
        ...this.getPlotVariableColumns()
      ])];

      // Convert filtered data to CSV format, but only include visible columns
      const flattenedData = this.filteredData.map(row => {
        const visibleData = {};
        visibleColumns.forEach(column => {
          visibleData[column] = row[column];
        });
        return flattenObject(visibleData);
      });
      
      // Get headers from flattened data
      const headers = [...new Set(
        flattenedData.flatMap(row => Object.keys(row))
      )].sort();
      
      // Create CSV content
      const csvContent = [
        headers.join(','),
        ...flattenedData.map(row => 
          headers.map(header => {
            let value = row[header];
            
            // Handle object values by converting to JSON
            if (value && typeof value === 'object') {
              try {
                value = JSON.stringify(value);
              } catch (e) {
                value = '[Object]';
              }
            }

            // Handle values that need quotes
            if (typeof value === 'string' && (value.includes(',') || value.includes('"'))) {
              return `"${value.replace(/"/g, '""')}"`;
            }
            return value ?? '';
          }).join(',')
        )
      ].join('\n');

      // Create and trigger download
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', 'gosset_data.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    initializeVisibleColumns() {
      // Use default columns from config
      this.visibleColumns = [...this.config.defaultVisibleColumns];
    },

    toggleColumn(column) {
      const index = this.visibleColumns.indexOf(column);
      if (index === -1) {
        this.visibleColumns.push(column);
      } else {
        this.visibleColumns.splice(index, 1);
      }
    },

    // Helper methods for getting active columns
    getActiveFilterColumns() {
      const activeColumns = [];
      
      // Add columns with active multichoice filters
      Object.entries(this.filterValues).forEach(([column, values]) => {
        if (values && values.length > 0) {
          activeColumns.push(column);
        }
      });
      
      // Add columns with active range filters
      Object.entries(this.selectedRanges).forEach(([column, range]) => {
        if (range && (range.min !== null || range.max !== null)) {
          activeColumns.push(column);
        }
      });
      
      return activeColumns;
    },

    getPlotVariableColumns() {
      const plotColumns = [];
      
      if (this.selectedXVar) {
        plotColumns.push(this.selectedXVar);
      }
      
      if (this.selectedYVar) {
        plotColumns.push(this.selectedYVar);
      }
      
      return plotColumns;
    },

    setDiseaseFromRoute(disease) {
      const doid = this.config.diseaseMapping[disease];
      if (doid) {
        this.config.doid = doid;
      } else {
        console.warn(`Unknown disease route: ${disease}, falling back to default`);
        this.config.doid = 'DOID_9970'; // fallback to obesity
      }
    },

    // New action to apply an analysis
    applyAnalysis(analysis) {
      if (analysis.filters) {
        // Reset existing filters
        this.filterValues = {};
        this.selectedRanges = {};

        // Apply filters from the analysis
        Object.keys(analysis.filters).forEach((filterVar) => {
          const values = analysis.filters[filterVar];
          const filter = this.config.filters.find((f) => f.var === filterVar);
          if (filter.type === 'multichoice' || filter.type === 'boolean') {
            this.filterValues[filterVar] = values;
          } else if (filter.type === 'range') {
            this.selectedRanges[filterVar] = values;
          }
        });
      }

      if (analysis.chart) {
        // Apply chart settings from the analysis
        this.selectedXVar = analysis.chart.selectedXVar ?? this.selectedXVar;
        this.selectedBaseYVar = analysis.chart.selectedBaseYVar ?? this.selectedBaseYVar;
        this.selectedValueType = analysis.chart.selectedValueType ?? this.selectedValueType;
        this.selectedYVar = `${this.selectedBaseYVar} ${this.selectedValueType}`;
        this.selectedChartType = analysis.chart.selectedChartType ?? this.selectedChartType;
        this.selectedUnit = analysis.chart.selectedUnit ?? this.selectedUnit;
        this.selectedSort = analysis.chart.selectedSort ?? this.selectedSort;
      }
    },
  },

  getters: {
    getData: (state) => state.data,
    isLoading: (state) => state.loading,
    getError: (state) => state.error,
    filteredData: (state) => {
      return state.data.filter(item => {
        // Check all filters (including population filters)
        return state.config.filters.every(filter => {
          // Determine if filter is active
          const isFilterActive = filter.type === 'multichoice' || filter.type === 'boolean'
            ? state.filterValues[filter.var]?.length > 0
            : filter.type === 'range' && state.selectedRanges[filter.var]
              ? (state.selectedRanges[filter.var].min !== null || state.selectedRanges[filter.var].max !== null)
              : false;

          // Skip filtering if the filter is not active
          if (!isFilterActive) return true;

          // Get the value from the item
          const itemValue = item[filter.var];

          // Handle multichoice filters
          if (filter.type === 'multichoice') {
            const selectedValues = state.filterValues[filter.var] || [];
            if (selectedValues.length === 0) return true;
            
            // Check if NA is selected
            const isNASelected = selectedValues.includes('NA');
            
            // If NA is selected and the value is null/empty/undefined, include it
            if (isNASelected && (
              itemValue === null || 
              itemValue === undefined || 
              itemValue === '' || 
              (Array.isArray(itemValue) && itemValue.length === 0)
            )) {
              return true;
            }
            
            // If the value is null/empty/undefined and NA is not selected, exclude it
            if (itemValue === null || 
                itemValue === undefined || 
                itemValue === '' || 
                (Array.isArray(itemValue) && itemValue.length === 0)) {
              return false;
            }
            
            // If the item value is an array, check for intersection
            if (Array.isArray(itemValue)) {
              return itemValue.some(val => selectedValues.includes(val));
            }
            
            // If it's a single value, check if it's included in selected values
            return selectedValues.includes(itemValue);
          }

          // Skip filtering if the filtered variable is missing, null, undefined, or NaN
          if (itemValue === undefined || itemValue === null || 
              (typeof itemValue === 'number' && isNaN(itemValue))) {
            return false;
          }

          // Handle boolean filters (including eligibility filters)
          if (filter.type === 'boolean') {
            const selectedValues = state.filterValues[filter.var] || [];

            // If no checkboxes are selected, show all data points
            if (selectedValues.length === 0) return true;

            // Normalize itemValue
            let normalizedItemValue;
            if (itemValue === true) normalizedItemValue = 'true';
            else if (itemValue === false) normalizedItemValue = 'false';
            else normalizedItemValue = 'undefined';

            // Include the item if its normalized value is among the selected values
            return selectedValues.includes(normalizedItemValue);
          }

          // Skip filtering if the filtered variable is missing, null, undefined, or NaN
          if (itemValue === undefined || itemValue === null || 
              (typeof itemValue === 'number' && isNaN(itemValue))) {
            return false;
          }

          // Handle multichoice filters
          if (filter.type === 'multichoice') {
            const selectedValues = state.filterValues[filter.var] || [];
            if (selectedValues.length === 0) return true;
            
            // Check if NA is selected
            const isNASelected = selectedValues.includes('NA');
            
            // If NA is selected and the value is null/empty/undefined, include it
            if (isNASelected && (
              itemValue === null || 
              itemValue === undefined || 
              itemValue === '' || 
              (Array.isArray(itemValue) && itemValue.length === 0)
            )) {
              return true;
            }
            
            // If the value is null/empty/undefined and NA is not selected, exclude it
            if (itemValue === null || 
                itemValue === undefined || 
                itemValue === '' || 
                (Array.isArray(itemValue) && itemValue.length === 0)) {
              return false;
            }
            
            // If the item value is an array, check for intersection
            if (Array.isArray(itemValue)) {
              return itemValue.some(val => selectedValues.includes(val));
            }
            
            // If it's a single value, check if it's included in selected values
            return selectedValues.includes(itemValue);
          }
          
          // Handle range filters
          if (filter.type === 'range') {
            const range = state.selectedRanges[filter.var] || {};
            
            // Skip if no range is set
            if (!range.min && !range.max) return true;
            
            // Handle date values
            if (state.config.variableType[filter.var] === 'date') {
              const dateValue = new Date(itemValue);
              if (range.min && dateValue < range.min) return false;
              if (range.max && dateValue > range.max) return false;
              return true;
            }
            
            // Handle numeric values (including dynamically created endpoints)
            const value = itemValue?.value !== undefined 
              ? Number(itemValue.value) 
              : Number(itemValue);
              
            if (isNaN(value)) return false;
            
            if (range.min !== null && value < Number(range.min)) return false;
            if (range.max !== null && value > Number(range.max)) return false;
            return true;
          }
          
          return true;
        });
      });
    },

    availableColumns: (state) => {
      const allColumns = new Set();
      state.data.forEach(dataPoint => {
        Object.keys(dataPoint).forEach(key => {
          // Filter out nested objects and internal properties
          if (typeof dataPoint[key] !== 'object' && !key.startsWith('_')) {
            allColumns.add(key);
          }
        });
      });
      return Array.from(allColumns);
    },

    // Add new getter for display names
    getDisplayName: (state) => (variable) => {
      if (!variable) return ''; // Handle null/undefined variable names
      return state.config.variableDisplayNames[variable] || 
        variable.split('_').map(word => 
          word ? word.charAt(0).toUpperCase() + word.slice(1) : ''
        ).join(' ');
    },

    // Add new getter for variable types
    getVariableType: (state) => (variable) => {
      return state.config.variableType[variable] || 'float';
    },

    isContinuous: (state) => (variable) => {
      if (["num_sites", "num_countries", "num_patients"].includes(variable)) {
        return true;
      }
      const varType = state.config.variableType[variable];
      return varType === 'date' || varType === 'float';
    },

    isDate: (state) => (variable) => {
      return state.config.variableType[variable] === 'date';
    },

    // Add new getter for accepted units
    isAcceptedUnit: (state) => (unit) => {
      if (!unit) return false;
      if (unit === '%') return true;
      return Object.values(state.config.acceptedUnits).includes(unit);
    },

    // Add new getter for disease name
    getDiseaseName: (state) => {
      return state.config.diseaseParameters[state.config.doid].name || 'Unknown Disease';
    },

    // New getter to access analyses
    getAnalyses: (state) => {
      const diseaseParams = state.config.diseaseParameters[state.config.doid];
      return diseaseParams?.analyses || [];
    },

    // Add new getters for defaults
    defaultXVar: (state) => {
      const diseaseParams = state.config.diseaseParameters[state.config.doid];
      return diseaseParams?.defaultXVar || state.config.defaultXVar || 'mechanisms';
    },

    defaultValueType: (state) => {
      const diseaseParams = state.config.diseaseParameters[state.config.doid];
      return diseaseParams?.defaultValueType || state.config.defaultValueType || 'diff';
    }
  },
});
